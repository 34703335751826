import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import DaysToGo from '../../Components/DaysToGo.js';
import JobsPrint from './JobsPrint.js'
import ReactToPrint from 'react-to-print';
import queryString from "query-string"

class Jobs extends Component {
    state = {
        loading: true,
        jobCompletedId: -1,
        sort:{
            Id: 0,
            DueDateTime: 1
        },
        search: "",
        data: [],
        Companies: [],
        query: "",
        title: "All",
        editing: false,
        redirect: ""
    }
    async componentDidMount(){
        const {databaseRequest,setPage,view}=this.props;
        let t =this;

        window.addEventListener('scroll', this.handleScroll);
        var query = queryString.parse(this.props.location.search);
        var search = "";
        if(typeof query.q !== 'undefined'){
            search = query.q;
        }
        var position = 0;
        if(typeof query.p !== 'undefined'){
            position = query.p;
        }

        setPage(`Jobs`);
        var query = "";
        var title = "";
        var icon = "";
        var d = new Date().getTime();
        this.jobInput.focus();

        if(view==="all"){query="State:not=-1";title="All";icon=""}
        if(view==="active"){query="State:not=8&State:not=11&State:not=20&State:not=10";title="Active";icon="minus-circle"}
        if(view==="outstanding"){query=`State:not=8&State:not=11&State:not=20&DueDateTime:<=${d}&State:not=10`;title="Outstanding";icon="x-circle"}
        
        if(view==="workshop"){query="State=5";title="Workshop";icon="activity"}
        if(view==="quoting"){query="State=1";title="Requires Quoting";icon="activity"}
        if(view==="scheduling"){query="State=4";title="Requires Scheduling";icon="activity"}
        if(view==="invoicing"){query="State=8";title="Requires Invoicing";icon="activity"}
        if(view==="urgent"){query="State=5&IsUrgent=1";title="Urgent Jobs";icon="bell"}
        if(view==="completed"){query="State=11";title="Completed";icon="check-circle"}
        t.setState({view: view});
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        var laserNests = await databaseRequest("/nestparts/assoc-jobs", "GET");

        databaseRequest(`/jobs?${query}&Name:br-like=%25${search}%25&CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25&Id:or_like-br=%25${search}%25`,"GET").then((data)=>{
            if(typeof data.error !== 'undefined'){
                t.setState({
                    search: search,
                    position: position,
                    view: view,
                    Companies: companies,
                    Contacts: contacts,
                    LaserNests: laserNests,
                    loading: false,
                    query: query,
                    title:title,
                    icon: icon
                })
            }
            else{
                t.setState({
                    search: search,
                    position: position,
                    view: view,
                    Companies: companies,
                    Contacts: contacts,
                    LaserNests: laserNests,
                    loading: false,
                    data: data,
                    query: query,
                    title:title,
                    icon: icon
                })
            }
            window.scrollTo(0, position);
        });
    }
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }
    LaserJobs = (JobId) =>{
        const {LaserNests} = this.state;
        var Nests = [];
        for(var i=0; i<LaserNests.length; i++){
            if(LaserNests[i].JobId==JobId){
                Nests.push(LaserNests[i].NestId);
            }
        }
        return Nests;
    }
    handleScroll =  (event) => {
        var view = this.state.view;
        if(typeof view=="undefined"){
            view="active";
        }
        if(view=="urgent"){
            this.props.history.replace(`/urgent?q=${this.state.search}&p=${window.pageYOffset}`);
        }
        else{
            this.props.history.replace(`/jobs-${view}?q=${this.state.search}&p=${window.pageYOffset}`);
        }
        
    }
    sortJobId = ()=>{
        var {data, sort}=this.state;
        if(sort.Id==-1 || sort.Id==0){
            data = data.sort( ( a, b ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=1;
            sort.DueDateTime=0;
        }
        else if(sort.Id==1){
            data = data.sort( ( b, a ) => {
                if ( a.Id < b.Id ){
                return -1;
                }
                if ( a.Id > b.Id ){
                return 1;
                }
                return 0;
            });
            sort.Id=-1;
            sort.DueDateTime=0;
        }
        this.setState({data: data, sort: sort})
    }
    sortDueDateTime = ()=>{
        var {data, sort}=this.state;
        if(sort.DueDateTime==-1 || sort.DueDateTime==0){
            data = data.sort( ( a, b ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=1;
        }
        else if(sort.DueDateTime==1){
            data = data.sort( ( b, a ) => {
                if ( a.DueDateTime < b.DueDateTime ){
                return -1;
                }
                if ( a.DueDateTime > b.DueDateTime ){
                return 1;
                }
                return 0;
            });
            sort.Id=0;
            sort.DueDateTime=-1;
        }
        this.setState({data: data, sort: sort})
    }
    jobCompleted = async (JobId) =>{
        const {databaseRequest, params}=this.props;
        const {query,search} = this.state;
        let t = this; 
        t.setState({
            jobCompletedId: JobId
        });
        var req = {
            url: `/jobs/${JobId}/state/8`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            databaseRequest(`/jobs?${query}&Name:br-like=%25${search}%25&CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25&Id:or_like-br=%25${search}%25`,"GET").then((data)=>{
                t.setState({
                    jobCompletedId: -1,
                    data: data
                });
            });
        }, 500);
    }
    invoiceSent = async (JobId) =>{
        const {databaseRequest, params}=this.props;
        const {query,search} = this.state;
        let t = this; 
        t.setState({
            jobCompletedId: JobId
        });
        var req = {
            url: `/jobs/${JobId}/state/11`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            databaseRequest(`/jobs?${query}&Name:br-like=%25${search}%25&CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25&Id:or_like-br=%25${search}%25`,"GET").then((data)=>{
                t.setState({
                    jobCompletedId: -1,
                    data: data
                });
            });
        }, 500);
    }
    onSearch = async (event) => {
        const {databaseRequest,view}=this.props;
        var search = event.target.value;
        
        this.setState({loading: true, search: search});
        search = encodeURIComponent(search);
        this.props.history.replace(`/jobs-${this.state.view}?q=${search}`);

        databaseRequest(`/jobs?${this.state.query}&Name:br-like=%25${search}%25&CompanyName:or_like=%25${search}%25&ContactName:or_like=%25${search}%25&Id:or_like-br=%25${search}%25`,"GET").then((data)=>{
                this.setState({
                    loading: false,
                    data: data.length>0?data:[]
                });
        });
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                return Companies[i].Name;
            }
        }
        return null;
    }
    toggleUrgency = async (id) =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        const {data}=this.state;
        var JobIndex;
        for(let i=0; i<data.length; i++){
            if(data[i].Id==id){
                JobIndex = i;
            }
        }
        data[JobIndex].IsUrgent = data[JobIndex].IsUrgent==1?0:1;
        this.setState({loading: false,data:data, loadingMsg: `Updating urgency...`});
        if(id!=-1){
            var req = {
                url: `/jobs/${id}/urgency/${data[JobIndex].IsUrgent}`,
                method: "PUT"
            }
            await databaseRequest(req.url,req.method,JSON.stringify({}));
            setTimeout(()=>{
                t.setState({
                    loading: false,
                    data: data,
                    loadingMsg: `Updating state...`
                });
            }, 100);
        }
        else{
            t.setState({
                loading: false,
                loadingMsg: `Updating state...`
            });
        }
    }
    table = () => {    
        const access = localStorage.getItem("access");
        if(this.state.loading){
                return (
                    <div className="w-100 text-center">
                        <h1 className="h3 mb-3 font-weight-normal">Loading jobs...</h1>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
            else{
                return (
                    <table className="table table-striped ">
                    <thead className="thead-dark ">
                        <tr>
                        {access>50 && !this.state.editing?(<th scope="col" style={{width: '20px'}}></th>):(<React.Fragment></React.Fragment>)}    
                        <th scope="col" className="cursor-pointer" onClick={this.sortJobId}>
                            Job No. {this.state.sort.Id==1?(<i className="fe fe-chevron-down mr-2"></i>):this.state.sort.Id==-1?(<i className="fe fe-chevron-up mr-2"></i>):(<i className="fe fe-minus mr-2"></i>)}
                        </th>
                        
                        <th scope="col">Company</th>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-right">Created Date</th>
                        <th scope="col" className="text-right cursor-pointer" onClick={this.sortDueDateTime}>
                            Due Date
                            {this.state.sort.DueDateTime==1?(<i className="fe fe-chevron-down mr-2"></i>):this.state.sort.DueDateTime==-1?(<i className="fe fe-chevron-up mr-2"></i>):(<i className="fe fe-minus mr-2"></i>)}
                        </th>
                        <th scope="col" className="text-right">State</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((row, i) => {
                        var Nests = this.LaserJobs(row.Id);
                        return (
                        <tr key={i} className={(this.state.jobCompletedId===row.Id)?"bg-warning":row.IsUrgent==1?"bg-danger-light":""}>

                            {access>50 && !this.state.editing?
                            <React.Fragment>
                            {(row.State==5)?(<td>
                                <i className="fe fe-check-circle link" onClick={()=>{this.jobCompleted(row.Id)}}></i>
                                <i className="fe fe-bell ml-5 link" onClick={()=>{this.toggleUrgency(row.Id)}}></i>
                                </td>):(<React.Fragment></React.Fragment>)}      
                            {(row.State==8)?(<td><i className="fe fe-send link" onClick={()=>{this.invoiceSent(row.Id)}}></i></td>):(<React.Fragment></React.Fragment>)}
                            {(row.State!=8 && row.State!=5)?(<td></td>):(<React.Fragment></React.Fragment>)}
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>}


                            {this.state.editing?<td><button type="checkbox" onClick={()=>{this.toggleUrgency(row.Id)}}className={row.IsUrgent==1?"btn btn-danger":"btn btn-light"}><i className="fe fe-x"></i></button></td>:<React.Fragment></React.Fragment>}

                            <th scope="row" ><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{row.Id}</Link></th>
                            
                            <td><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{this.getCompanyName(row.CompanyId, row.ContactId)}</Link></td>
                            <td><Link to={`/jobs/${row.Id}?return=${this.props.location.pathname}`}>{row.Name} ({row.ProcessCount})</Link>
                            <br/>
                            {Nests.length > 0?(
                                <Link to={`/jobs/${row.Id}/laser?return=${this.props.location.pathname}`}><i className='fe fe-sunset link mt-2 ml-1'></i></Link>
                            ):(<></>)}
                            </td>
                            <td className="text-right"><p><b><TimeAgo time={row.CreatedDateTime} /></b></p> ({new Date(row.CreatedDateTime).toLocaleDateString()})</td>
                            <td className="text-right"><DaysToGo time={row.DueDateTime} businessdays={true} state={row.State}/> ({new Date(row.DueDateTime).toLocaleDateString()})</td>
                            <td className="text-right"><State state={row.State} /></td>
                        </tr>)
                    })}
                    </tbody>
                    </table>
                )
            }
    }

    render(){
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        return (
            <main role="main" className="container">
                <h2><i className="fe fe-flag"></i> {this.state.title} Jobs List <i className={"fe fe-"+this.state.icon}></i></h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input ref={(input) => { this.jobInput = input; }}  className="form-control mr-sm-2" type="search" onChange={this.onSearch} value={this.state.search} placeholder="Search" aria-label="Search" />                      
                        </form>
                    </div>
                    <div className="col p-0">
                        

                        {!this.state.editing?
                        <Link to={`/jobs/-1?return=${this.props.location.pathname}`}><button type="button" className="btn btn-success float-right">New Job</button></Link> 
                        :<React.Fragment></React.Fragment>}                      
                        {!this.state.editing?<ReactToPrint
                            trigger={() => {
                                return <button type="button" className="btn btn-secondary float-right mr-3">Print</button>
                            }}
                            content={() => this.componentRef}
                        />:<React.Fragment></React.Fragment>}
                        {/*this.props.view!=="invoicing" && this.props.view!=="completed"?<button type="button" className={this.state.editing?"btn btn-info float-right mr-3":"btn btn-danger float-right mr-3"} onClick={()=>{this.setState({editing: !this.state.editing})}}>{!this.state.editing?"Mark  Urgent Jobs":"Exit Editing"}</button>:<React.Fragment></React.Fragment>*/}

                        <div style={{ display: "none" }}><JobsPrint sort={this.state.sort} {...this.props} ref={el => (this.componentRef = el)} /></div>

                    </div>
                </div>
                <div className="row">
                    {this.table()}

                </div>
                <button className="btn btn-info" style={{position: "fixed", bottom: "5px", right: "5px" }} onClick={()=>{window.scrollTo(0,0);}}><i className="fe fe-chevron-up"></i></button>
            </main>

            
        )
    }
}

export default Jobs;