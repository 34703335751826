import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import {Link, Redirect} from "react-router-dom";
import Pricing from './Pricing';
import TimeAgo from '../../Components/TimeAgo.js';
import Autosuggest from 'react-autosuggest';
import LaserThumbnail from '../../Components/LaserThumbnail.js'
import NestEditPrint from './NestEditPrint.js';

class UsersEdit extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading nest...",
        redirect: "",
        processes: [],
        updateRequired: false,
        data: null,
        JobIdSuggestions: [],
        history: [],
        JobIdSearch: "",
        rowClicked: -1,
        modalApplyToAll: false,
        firstJobAssociated: false,
    }
    onChange = (e) => {
        let data = this.state.data;

        
        if(e.target.name=="Material"){
            if(!Pricing.checkThickness(e.target.value, data.Thickness))return;
        }
 

        data[e.target.name]=e.target.value;
        if(e.target.name=="SheetRatio"){
            data[e.target.name]=Number(e.target.value);
        }

        //if(e.target.name=="CostCuttingString" || e.target.name=="CostHandlingString" || e.target.name=="CostMaterialString"){
        if(e.target.name != "Material" && e.target.name != "Thickness" && e.target.name != "SheetRatio"){
            data.Prediction = false;
        }
        else{
            data.Prediction = true;
            
        }
        var overrride = e.target.name == 'CostCuttingString' || e.target.name == 'CostMaterialString' || e.target.name == 'CostHandlingString'
        
        data = Pricing.processData(data, overrride);
        


        this.setState({ data: data, updateRequired:true});  
    };
    adjustQty = (Id, e)=>{
        var Qty = Number(e.target.value);
        if(!isNaN(Qty)){
            Qty = Qty < 0?0:Qty; //Don't allow negative numbers
            this.setState({ ModifyQty: Qty});
        }
    }
    saveAdjustment = (Id)=>{
        let {data, ModifyQty} = this.state;

        for(var i=0; i<data.Parts.length; i++){
            if(data.Parts[i].Id==Id ){        
                data.Parts[i].ModifyQty = ModifyQty;
            }
        }
        data = Pricing.processData(data);
        this.setState({ PartEdit:-1, data: data, updateRequired:true});
        
    }
    setEditRow = (row)=>{
        if(row==this.state.PartEdit){
            this.setState({PartEdit: -1});
        }
        else{
            this.setState({PartEdit: row});
        }
        
    }
    setJobData = (e, i, key) =>{
        let data = this.state.data;
        if(i in data.Jobs){
            if(key in data.Jobs[i]){
                data.Jobs[i][key] = e.target.value;
            }        
        }
        data = Pricing.processData(data, true);
        this.setState({ data: data, updateRequired:true});
    }
    priceChange= () => {
        let data = this.state.data;
        var cut = Number(data.CostCuttingString);
        var mat = Number(data.CostMaterialString);
        var hand = Number(data.CostHandlingString);
        var update = false;

        if(!isNaN(cut)){
            data.CostCutting=cut;
            //data.Prediction = false;
            update=true;
        }


        if(!isNaN(mat)){
            data.CostMaterial=mat;
            //data.Prediction = false;
            update=true;
        }


        if(!isNaN(hand)){
            data.CostHandling=hand;
            //data.Prediction = false;
            update=true;
        }
        data.CostCuttingString = data.CostCutting.toFixed(2);
        data.CostMaterialString = data.CostMaterial.toFixed(2);
        data.CostHandlingString = data.CostHandling.toFixed(2); 
        data.CostTotal = data.CostCutting + data.CostMaterial + data.CostHandling;
        /*for(var i=0; i<data.Parts.length; i++){
            data.Parts[i].CostEach = data.CostTotal * (data.Parts[i].cuttingPercent/100);
        }*/
        this.setState({ data: Pricing.processData(data), updateRequired:update});  
    };
    saveBtn = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.JobName}...`
        });
        var data = this.state.data;
        var req = {
            url: `/nests/${params.NestId}`,
            method: "PUT"
        }
        if(params.NestId!=="-1"){


            var existingData = await databaseRequest(`/nests/${params.NestId}`,"GET");
            existingData.Material = data.Material;
            existingData.Thickness = data.Thickness;
            existingData.State = data.State;
            
            if(!data.Prediction){
                existingData.CostCutting = data.CostCutting;
                existingData.CostMaterial = data.CostMaterial;
                existingData.CostHandling = data.CostHandling;
            }
            for(let i=0; i<existingData.Parts.length; i++){
                
                var change = false;
                for(let j=0; j<data.Parts.length; j++){
                    if(existingData.Parts[i].Id==data.Parts[j].Id){
                        existingData.Parts[i].JobId=data.Parts[j].JobId;
                        existingData.Parts[i].ModifyQty=data.Parts[j].ModifyQty;
                        existingData.Parts[i].CostCutting = data.Parts[j].CostCutting.toFixed(4);
                        existingData.Parts[i].CostMaterial = data.Parts[j].CostMaterial.toFixed(4);
                        existingData.Parts[i].CostHandling = data.Parts[j].CostHandling.toFixed(4);
                        change = true;
                    }
                }
                if(change){
                    await databaseRequest(`/nestparts/${existingData.Parts[i].Id}`,"PUT", 
                        JSON.stringify({
                            JobId: existingData.Parts[i].JobId, 
                            CostCutting: existingData.Parts[i].CostCutting,
                            CostMaterial: existingData.Parts[i].CostMaterial,
                            CostHandling: existingData.Parts[i].CostHandling,
                            ModifyQty: existingData.Parts[i].ModifyQty
                        }));
                }
            }

            delete existingData["Image"];
            var newData = await databaseRequest(req.url,req.method, JSON.stringify(existingData));
            this.setState({
                redirect: "/redirector?r=nests/"+params.NestId,
                loading: false,
                loadingMsg: `Saving ${this.state.data.Name}...`
            });
        }
    }
    updateBtn = ()=>{
        let data = this.state.data;
        data.Prediction = false;
        data = Pricing.processData(data);
        this.setState({
            data: data
        })
        let t = this;
        setTimeout(()=>{
            t.saveBtn();
        },500);
        
    }
    resetBtn = ()=>{
        let data = this.state.data;
        data = Pricing.resetNest(data);

        this.setState({
            data: data
        })
        let t = this;
        setTimeout(()=>{
            t.saveBtn();
        },500);
        
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    jobData = (i,key) =>{
        let data = this.state.data.Jobs;
        if(i in data && key in data[i]){  
            return data[i][key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("JobName")+"..."
        });

        databaseRequest(`/nests/${params.SupplierId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading nest...",
                    redirect: "/nests"
                });
            }, 1000);
        });

    }
    load = async ()=>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        setPage(`Nest`);
        if(params.NestId==="-1"){
            t.setState({
                redirect: "/nests",
                loadingMsg: "Loading nest...",
                loading: false
            });
        }
        else{
            var data = await databaseRequest(`/nests/${params.NestId}`,"GET");

            data.Prediction = data.CostCutting == 0;
            var history = await databaseRequest(`/history/${data.Guid}`,"GET");

            setPage(`${data.JobName} - ${data.Thickness.toFixed(1)}mm`);
            t.setState({
                loading: false,
                data: Pricing.processData(data, false),
                history: history,
                updateRequired: false
            });
        }
    }
    async componentDidMount(){
        const {databaseRequest}=this.props;
        Pricing.loadPricing();
        var Jobs = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20`, 'GET');
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        this.setState({
            Companies: companies,
            Contacts: contacts,
            Jobs: Jobs
        });
        this.load();
    }

    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    updateState = (s)=>{
        let data = this.state.data;
        data.State = s;
        this.setState({ data: data }); 
    }
    getJobId = suggestion => suggestion.Id;
    renderJobIdSuggestion = suggestion => (
        
        <div>
          {suggestion.Id} - {this.getCompanyName(suggestion.CompanyId, suggestion.ContactId)} | {suggestion.Name}
        </div>
    );
    jobName = (Id, def="Job No.", _style={fontSize: "0.6rem"}) =>{
        var r = this.getJobIdSuggestions(Id);
        if(r.length==1){
            return (<div style={_style} >{this.renderJobIdSuggestion(r[0])}</div>);
        }
        return def
    }
    
    getJobIdSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.Jobs.filter(lang =>
          lang.Id.toString().slice(0, inputLength) === inputValue
        );
    };

    onJobIdFetchRequested = ({ value }) => {
        this.setState({
            JobIdSuggestions: this.getJobIdSuggestions(value)
        });
    };
    onJobIdClearRequested = () => {
        this.setState({
            JobIdSuggestions: []
        });
    };
    onJobIdChange = (event, { newValue }) => {
        var {data, rowClicked, firstJobAssociated}=this.state;
        if(event.type=="click"){
            if(rowClicked >= 0 && rowClicked < data.Parts.length){
                data.Parts[rowClicked].JobId = newValue.toString();
                this.setState({rowClicked: -1, firstJobAssociated: true, modalApplyToAll: !firstJobAssociated &&  data.Parts.length > 1?true:false});
            }
        }
        this.setState({
          updateRequired:true,
          data: Pricing.processData(data),
          JobIdSearch: event.type=="click"?"":newValue.toString()
        });
    };
    setJobIdForAll = ()=>{
        var {data, rowClicked}=this.state;
        var id = "";
        for(let i=0; i<data.Parts.length; i++){
           if( data.Parts[i].JobId.length > 0){
            id =  data.Parts[i].JobId;
           }
        }
        for(let i=0; i<data.Parts.length; i++){
            data.Parts[i].JobId = id;
        }
        this.setState({
            modalApplyToAll: false,
            data: Pricing.processData(data)
        });
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                
                return Companies[i].Name;
            }
        }
        return null;
    }
    setActiveRow = (id) =>{
        let t = this;
        this.setState({rowClicked: id});
        setTimeout(()=>{
            t.AutoSuggest.focus();
        }, 100);
        
    }
    
    partsList = ()=>{
        const {params} = this.props;
        const {data} = this.state;
        var JobIdSuggestions = this.state.JobIdSuggestions;

        var AssociateJobsIP = {
            placeholder: 'Assoc. Job',
            value: this.state.JobIdSearch,
            className: "form-control mb-2",
            onChange: this.onJobIdChange,
            autoComplete: "nofill",
            ref: (input) => { this.AutoSuggest = input; } 
        };


        return (
        <> {data.Parts.map((row, i) => {

            var cardClass = "card w-20 m-2";
            if("Highlight" in row){
                if(row.Highlight){
                    cardClass = "card w-20 m-2 bg-success";
                }
            }
            var _weight = row.Weight.toFixed(1)+"kg";
            if(row.Weight < 1){
                _weight = (row.Weight*1000).toFixed(1)+"g";
            }
            return (
                <div  key={i} className={cardClass} style={{width: "250px"}}>
                <LaserThumbnail className="card-img-top card-img-bottom mx-auto mt-2" style={{height: "200px", width: "200px"}} url={`/nestparts/${row.Id}/image`}/>
                {/*<img className="card-img-top card-img-bottom mx-auto mt-2" src={row.Image} style={{height: "200px", width: "200px"}} alt="Card image cap" />*/}
                
                <div className="card-body">
                    <h5 className="card-title mb-0" data-toggle="tooltip" data-placement="top" title="Weight of the blank rectangle including clearance"><strong>Item {i+1}</strong> <em>({row.BoundingBoxX.toFixed(1)} x {row.BoundingBoxY.toFixed(1)} - {row.BlankWeight.toFixed(1)}kg) </em> </h5>
                    <small><em data-toggle="tooltip" data-placement="top" title="Calculated weight using surface area x thickness x density">Approx. Weight: {_weight}</em> <small style={{fontSize: "0.5rem"}} data-toggle="tooltip" data-placement="top" title="Approx. weight / Blank weight usage">{row.Usage.toFixed(1)}%</small></small>
                    <div className="card-text">
                        {
                        row.Name.split("\\r\\n").map((text,j)=>{
                            if(text.length > 0){return (<p key={j} className='mb-0'> - <small>{this.camelize(text)}</small></p>);}
                        
                        })}
                         <p className='m-0 border-top'><small>Cut - Material - Handling</small></p>
                         <p className='m-0'><small>${row.CostCutting.toFixed(2)} + ${row.CostMaterial.toFixed(2)} + ${row.CostHandling.toFixed(2)}</small></p>
                         <p className='font-weight-bold mt-2 border-bottom'><small>Approx.</small> ${row.CostEach.toFixed(2)}ea - {row.Quantity + " off"}</p>

                        {data.State==1?(<>
                        {this.state.rowClicked == i?(
                            <Autosuggest
                                id="AS_SupplierName"
                                suggestions={JobIdSuggestions}
                                onSuggestionsFetchRequested={this.onJobIdFetchRequested}
                                onSuggestionsClearRequested={this.onJobIdClearRequested}
                                getSuggestionValue={this.getJobId}
                                renderSuggestion={this.renderJobIdSuggestion}
                                inputProps={AssociateJobsIP}
                            />
                        ):(
                            <div className='row'>
                            <div className='col-3 p-0 pr-1 '>
                            <Link to={`/jobs/${row.JobId}/laser`}>
                            <button disabled={row.JobId==-1} className="form-control btn btn-info text-center" type="button"><i className="fe fe-search m-0 p-0"></i></button>
                                {/**/}
                            </Link> 
                            </div>
                            <div className='col-9 p-0 pl-1'>
                            <p className="w-80 form-control text-right text-muted" onClick={()=>{this.setActiveRow(i)}}>
                                {this.jobName(row.JobId.toString())}
                            </p>
                            </div>
                            </div>
                        )}
                        </>):(<></>)}
                    </div>
                </div>
                </div>
            )





        })}
        </>
        );
    }
    multiNest= ()=>{
        return ( <>
            <h2 className='mt-4'>Multi-Job Nest</h2>
            <div className='form-row'>
            <table className='table text-black'>
                <thead className='thead-dark'>
                    <tr>
                    <th>Job</th>
                    <th>No. of Parts</th>
                    <th>Total Weight</th>
                    <th>Handling</th>
                </tr>
                </thead>
                <tbody>
                {this.state.data.Jobs.map((row,i)=>{
                    var line = {fontSize: "1rem", padding: "0px", margin: "0px", verticalAlign: "middle"};              
                    var qtyStr = row.Quantity + " off";
                    if(row.ModifyQty > 0){
                        qtyStr = row.Quantity + " + " + row.ModifyQty +" off";
                    }          
                    return (<>
                    <tr key={i}>
                        <td style={line}>{this.jobName(row.JobId.toString(), "Not Allocated", {})}</td>
                        <td style={line}>{qtyStr}</td>
                        <td style={line}>{row.Weight.toFixed(3)}kg</td>
                        <td style={{...line, width: "125px"}}><input type="text" className="form-control text-right m-1" placeholder="Handling" name={"CostHandlingJob_"+i} value={this.jobData(i,"CostHandlingString")} onFocus={(e)=>{this.partHighlight(row.JobId)}} onChange={(e)=>{this.setJobData(e,i, "CostHandlingString");}} onBlur={()=>{this.clearHighlight(); this.priceChange();}}/></td>
                    </tr></>);

                    })}
                </tbody>
            </table>
            </div>
            </>);
    }
    partHighlight = (JobId) =>{
        var {data} = this.state;
        for(var i=0;i<data.Parts.length; i++){
            if(data.Parts[i].JobId==JobId){
                data.Parts[i].Highlight = true;
            }
        }
        this.setState({data: data});
    }
    clearHighlight = ()=>{
        var {data} = this.state;
        for(var i=0;i<data.Parts.length; i++){
            data.Parts[i].Highlight = false;
        }
        this.setState({data: data});
    }
    buttonState(activeState, inActiveClass){
        var state = this.data("State");
        if(state==0)return `btn btn-${inActiveClass} float-right ml-2`;
        if(state==activeState)return `btn btn-${inActiveClass} float-right ml-2`;
        if(state!=activeState)return `btn btn-outline-${inActiveClass} float-right ml-2`;
    }
    page = () =>{
        const {props} = this;


        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        const {Prediction, Thickness, Usages, SheetRatioActual} = this.state.data;
        const {history} = this.state;
        var lastUpdated = "";
        if(history.length > 0){
            lastUpdated = history[0].Name;
        }
        var PredictionClass = "form-control text-right m-1 font-italic text-info";
        if(!Prediction){
            PredictionClass = "form-control text-right m-1";
        }

        var fullSheet = Pricing.fullSheet(Thickness);
        


        return (
            <main role="main" className="container">
            <div className="p-5">
                <h2><i className="fe fe-sunset"></i> Nest</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className="border-bottom">{this.data("JobName")}</h1>
                        <h5><b><TimeAgo time={this.data("CreatedDateTime")} /></b> ({new Date(this.data("CreatedDateTime")).toLocaleString()})</h5>
                    </div>
                    <div className="col p-0">
                        <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back</button>
                        {this.state.updateRequired?(<button type="button" className="btn btn-success float-right mr-3" onClick={()=>{this.updateBtn()}}>Confirm</button>):(<></>)}
                        {this.state.updateRequired?(<button type="button" className="btn btn-secondary float-right mr-3" onClick={()=>{this.load()}}>Undo</button>):(<></>)}
                        {this.state.updateRequired?<></>:<>
                        {this.state.data.State==0?(
                            <>
                                <button type="button" disabled className="btn btn-secondary float-right mr-2">Print (Allocate First)</button>
                            </>):(
                            <>
                                <ReactToPrint
                                    trigger={() => {
                                        return <button type="button" className="btn btn-secondary float-right mr-2">Print</button>
                                    }}
                                    content={() => this.componentRef}
                                />
                                <div style={{ display: "none" }}><NestEditPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                            </>
                        )}
                        </>}
                        <button type="button" className="btn btn-warning float-right mr-3" onClick={this.resetBtn}>Reset Nest</button>
                    </div>
                </div>
                <div className="row">
                    <form className="w-100">
                    <div className="form-row">
                        <div className="col">
                            <div className='row mb-4'>
                                <div className='col'>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="State" onChange={this.onChange} type="checkbox" id="inlineCheckbox1" value="2" checked={this.data("State")==2}/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Quote</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"  name="State" onChange={this.onChange} type="checkbox" id="inlineCheckbox2" value="1" checked={this.data("State")==1}/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Complete For Pricing</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"  name="State" onChange={this.onChange} type="checkbox" id="inlineCheckbox2" value="3" checked={this.data("State")==3}/>
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Cut | Not Priced</label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-5">
                                    <label>Material</label>
                                    <select className="form-control" name="Material" value={this.data("Material")} onChange={this.onChange}>
                                        {Pricing.Material.map((m, i)=>{
                                            return (<option key={i} value={m.Key}>{m.Name}</option>)
                                        })}
                                    </select>
                                </div> 

                                <div className="col-md-3">
                                    <label>Thickness</label>
                                    <select className="form-control" name="Thickness" value={this.data("Thickness")} onChange={this.onChange}>
                                        {Pricing.activeMaterial().Thicknesses.map((t, i)=>{
                                            return (<option key={i} value={t}>{t.toFixed(1)}mm</option>)
                                        })}
                                    </select>
                                </div>   

                                <div className="col-md-4" >
                                    
                                    <label>Material Usage</label>
                                    <select className="form-control" name="SheetRatio" value={this.data("SheetRatio")} onChange={this.onChange}>
                                        {Usages.map((p, i)=>{
                                            return (<option key={i} value={p}>{p.toFixed(1)}%</option>)
                                        })

                                        }
                                    </select>
                                    <p className="form-control text-right text-muted d-none">{this.data("SheetSizeX").toFixed(0)} x {this.data("SheetSizeY").toFixed(0)}</p>
                                </div>  
                            </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <label>Material Usage</label>
                                    <p className="form-control text-right text-muted">{this.data("SheetUsage").toFixed(2)} / {this.data("SheetArea").toFixed(2)} m2</p>
                                </div> 
                                <div className="col-md-3">
                                    <label>Run Time</label>
                                    <p className="form-control text-right text-muted">{this.minsToTime(this.data("RunTime"))}</p>
                                </div> 
                                <div className=" col-md-4">
                                    <label>Parts / Sheets</label>
                                    <p className="form-control text-right text-muted">{this.data("TotalParts")} / {this.data("NumberSheets")}</p>
                                </div> 
                            </div>

                            <div className="row">
                                <div className="col">
                                    <p className='mb-1' data-toggle="tooltip" data-placement="top" title="Weight and size of standard sheet size"><small>Standard Sheet:</small><br/>{fullSheet.width.toFixed(0)} x {fullSheet.height.toFixed(0)} - {fullSheet.weight.toFixed(1)}kg - ${fullSheet.price.toFixed(2)}</p>
                                    <p className='mb-1' data-toggle="tooltip" data-placement="top" title="Weight and size of nest"><small>Material Cost:</small><br/>{this.data("SheetSizeX").toFixed(0)} x {this.data("SheetSizeY").toFixed(0)} - {this.data("Weight").toFixed(0)}kg - ${this.data("SheetCost").toFixed(2)} {SheetRatioActual > 100?(<small className="text-danger" style={{fontSize: "0.6rem", verticalAlign: "text-top"}}>{this.data("SheetRatioActual").toFixed(1)}%</small>):(<></>)}</p>
                                    <p className='mb-0 d-none'><small>Material Usage:</small>  <small> {(this.data("SheetUtilisation")/100*this.data("Weight")).toFixed(1)}kg ({this.data("SheetUtilisation").toFixed(1)}%)</small></p>
                                    <p className='mb-0' data-toggle="tooltip" data-placement="top" title="Total of approx. weight"><small>Gross Part Weight: {this.data("TotalPartWeight").toFixed(1)}kg</small> </p>

                                    <p className='mb-0' data-toggle="tooltip" data-placement="top" title="Rounded up to nearest 5min. interval"><small>Allowed Run Time:</small> {this.minsToTime(this.data("AllowedRunTime"))}</p>
                                    <p className='mb-0' data-toggle="tooltip" data-placement="top" title="Material selected in Mazak software"><small>Material Setting:</small> {this.data("MaterialKey")}</p>
                                    <p className='mb-0'><small>Material Rate:</small> ${this.data("PricePerKg").toFixed(2)} /kg</p>
                                    <p className='mb-0'><small>Cutting Rate:</small> ${this.data("PricePerMin").toFixed(2)} /min</p>
                                    {lastUpdated.length>0?(<p className='mt-3 mb-0'><small style={{fontSize: '0.5rem'}}>LAST UPDATED BY: </small><br/>{lastUpdated}</p>):(<></>)}

                                    {lastUpdated.length==0?(<p style={{fontSize: '0.75rem', lineHeight: "0.75rem"}} className='mt-3 mb-0 text-uppercase font-weight-light alert alert-info p-3'>Estimation only, not confirmed by laser</p>):(<></>)}
                                    {this.data("ThicknessDoesNotMatch")==true?(<p style={{fontSize: '0.75rem', lineHeight: "0.75rem"}} className='mt-3 mb-0 text-uppercase font-weight-light alert alert-danger p-3'>Thickness does not match nest, cutting times maybe inaccurate</p>):(<></>)}
                                    {this.data("MaterialKey").substring(0,2)!=Pricing.Key.substring(0,2)?(<p style={{fontSize: '0.75rem', lineHeight: "0.75rem"}} className='mt-3 mb-0 text-uppercase font-weight-light alert alert-warning p-3'>Material cut settings don't match nest</p>):(<></>)}                               
                                </div>
                                <div className="col">
                                    <div className="row mb-0">
                                            <div className="col">      
                                                <label className="mt-3 float-right">Cutting Cost</label>  
                                            </div>                    
                                            <div className="col">                        
                                                <input type="text" className={PredictionClass} placeholder="Cutting" name="CostCuttingString" value={(this.data("CostCuttingString"))} onChange={this.onChange} onBlur={this.priceChange}/>    
                                            </div> 
                                    </div>
                                    <div className="row mb-0">
                                            <div className="col">     
                                            <label className="mt-3 float-right">Material Cost</label>   
                                            </div>                    
                                            <div className="col"> 
                                            <input type="text" className={PredictionClass} placeholder="Material" name="CostMaterialString" value={(this.data("CostMaterialString"))} onChange={this.onChange} onBlur={this.priceChange}/>    
                                            </div> 
                                    </div>
                                    <div className="row mb-0">
                                            <div className="col"> 
                                            <label className="mt-3 float-right">Handling Cost</label>  
                                            </div>                    
                                            <div className="col">
                                            <input type="text"  className={PredictionClass} placeholder="Handling" name="CostHandlingString" onChange={this.onChange} onBlur={this.priceChange} value={(this.data("CostHandlingString"))}  />
                                            </div> 
                                    </div>

                                    <div className="row mb-0">
                                            <div className="col">
                                            <label className="mt-3 float-right font-weight-bold text-right" style={{lineHeight: "0.75rem"}}>Total Cost<br/></label> 
                                            
                                            </div>                    
                                            <div className="col">      
                                            <input type="text" className="form-control text-right m-1 font-weight-bold" disabled={true} placeholder="Total" name="CostTotal" value={(this.data("CostTotal")).toFixed(2)} />                               
                                            </div> 
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            {this.data("RunTime")==0?(<p style={{fontSize: '0.75rem', lineHeight: "0.75rem"}} className='mt-3 mb-0 text-uppercase font-weight-light alert alert-danger p-3'>Failed Nest: No run time in nest</p>):(<></>)}
                                        </div>             
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="col">
                            <div className="row">
                            <div className="form-group col-md-1"></div>
                            <div className="form-group col-md-11">
                            <label>Preview</label>
                            <LaserThumbnail className="card-img-top card-img-bottom" style={{display:"block"}} url={`/nests/${this.props.params.NestId}/image`}/>

                            {/*<img src={this.data("Image")} style={{display:"block"}} className="card-img-top card-img-bottom" alt="..." />*/}
                            </div>  
                            </div>
                        </div>
    
                    </div>


                    <div className="form-row">
                         {this.partsList()}
                  
                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-6">
                            
                        {this.state.updateRequired?(<button type="button" className="btn btn-success ml-2" onClick={()=>{this.updateBtn()}}>Confirm</button>):(<></>)}
                            {this.props.params.NestId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}`}>
                                <button type="button" className="btn btn-dark ml-3">History</button>
                            </Link>
                            ):(<div></div>)}
                            <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#myModal" >Delete</button>
                        </div>
                    </div>
                    </form>
                </div>
                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Nest</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("JobName")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: this.state.modalApplyToAll ? 'block' : 'none' }} className="modal" id="myModal2" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Apply to all</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{this.setState({modalApplyToAll: false})}}>
                            <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Do you want to apply this job number to all parts?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={()=>{this.setJobIdForAll()}}>Yes</button>
                            <button type="button" className="btn btn-secondary"  onClick={()=>{this.setState({modalApplyToAll: false})}}>No</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: this.state.modalApplyToAll ? 'block' : 'none' }} className='modal-backdrop show'></div>
            </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
    camelize(str) {
        str = str.toLowerCase();
        return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        }).replace(/\s+/g, ' ');
    }
    minsToTime(min){
        var hh = Math.floor(min/60);
        var mm = (min - (hh*60));
        var ss = (min%1)*60;
        var hhStr = hh<10?"0"+hh.toFixed(0):hh.toFixed(0);
        var mmStr = mm<10?"0"+mm.toFixed(0):mm.toFixed(0);
        var ssStr = ss<10?"0"+ss.toFixed(0):ss.toFixed(0);
        return  hhStr+ ":" + mmStr + ":" +  ssStr;
    }
}

export default UsersEdit;