import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { DatabaseRequest } from '../Components/DatabaseRequest';

export const DeliveryName = ({Title, Name, ClassName, onValidSuggestion, onUpdate, Document, Version}) =>{
    const [value, setValue] = useState("");
    const [valid, setValid] = useState("");
    const [NameList, setNameList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    //Run once
    useEffect(() => {  
        const fetchData = async () => {
            try {
                const response = await DatabaseRequest(`/delivery/autosuggest`, "GET");
                if (response.status!=200) {
                throw new Error('Network response was not ok');
                }
                delete response.status;
                setNameList(response);
            } catch (error) {
                console.error(error.message); 
            } 
        }
        fetchData();
    }, []);

    useEffect(()=>{
            const defaultValue = (Document || {}).hasOwnProperty(Name)?Document[Name].value:"";
            const defaultValid = (Document || {}).hasOwnProperty(Name)?Document[Name].valid:"";
            setValue(defaultValue);
            setValid(defaultValid);
    }, [Version]);




    function FetchRequested({value}){
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var results = inputLength === 0 ? [] : NameList.filter(lang => lang.Name.toLowerCase().slice(0, inputLength) === inputValue);
        for(let i=0; i<results.length; i++){
            results[i].Index = i;
        }
        setSuggestions(results);
    }
    function ClearRequested(){
        setSuggestions([]);
    }
    function SuggestionSelected(event, {suggestion, suggestionValue}){
        onChange(event, {newValue: suggestionValue});
        onValidSuggestion(suggestion);
    }
    function RenderSuggestion(suggestion){
        if(suggestion.Index==0){
            return <div className='w-100'>{suggestion.Name}<br/><small>{suggestion.Address}</small></div>
        }
        return (<div style={{fontSize: '0.5rem'}}>{suggestion.Name} - <small>{suggestion.Address}</small></div>);
    }
    function GetSuggestionValue(suggestion){
        return suggestion.Name;
    }
    function onChange(event, { newValue }){
        if(event.type=='click'){
            
            
        }
        else{

        }
        setValid(onUpdate(Name, newValue));
        setValue(newValue);

    }
    const validClass = (valid!=="" && valid?"is-valid ":"") + (valid!=="" && !valid?"is-invalid ":"");
    return (
            <div className={"form-group "+ClassName}>
                <label>{Title}</label>
                <Autosuggest
                    id="AS_DeliveryName"
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={FetchRequested}
                    onSuggestionsClearRequested={ClearRequested}
                    onSuggestionSelected={SuggestionSelected}
                    getSuggestionValue={GetSuggestionValue}
                    renderSuggestion={RenderSuggestion}
                    inputProps={{
                        placeholder: Title,
                        value: value,
                        className: "form-control "+validClass,
                        onChange: onChange,
                        disabled: false,
                        autoComplete: "nofill"
                      }}
                />
            </div>   
      );

};
