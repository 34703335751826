import React, { useState, useEffect, useRef} from 'react';
import { DatabaseRequest } from './DatabaseRequest';

function Thumbnail({url, className, style}){
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState("");

    const newStyle = {
        ...style
    };
    useEffect(() => {  
        const fetchData = async () => {
            try {
            const response = await DatabaseRequest(url, "GET");
            if (response.status!=200) {
                throw new Error('Network response was not ok');
            }
            setData(response.Image); 
            } catch (error) {
            //setError(error.message); 
            } finally {
            setLoading(false);
            }
        };
        fetchData();
    }, []);  


    return (<div>{!loading ? <img className={className} src={data} style={newStyle} alt="Laser Thumbnail" /> : "Loading..."}</div>);
}


export default Thumbnail;
