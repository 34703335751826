import React, { useState, useEffect } from 'react';
import { DatabaseRequest } from '../../Components/DatabaseRequest';
import SetPage from '../../Components/SetPage';
import SearchMain from '../../Standard/SearchMain';
import {Table} from '../../Standard/Table';
import {Container} from '../../Standard/EditForm';

function DeliveryDockets() {
  SetPage("Delivery Docket");
  const [data, setData] = useState([]);  
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null); 
  const [search, setSearch] = useState(""); 
  useEffect(() => {  
    const fetchData = async () => {
      try {
        const response = await DatabaseRequest(`/delivery?Name:like=%25${search}%25`, "GET");
        if (response.status!=200) {
          throw new Error('Network response was not ok');
        }
        delete response.status;
        setData(response.reverse()); 
      } catch (error) {
        setError(error.message); 
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [search]);  // Empty dependency array to run only once on mount

  // Handling the loading state
  if (loading) {
    return <Container><h2>Loading...</h2></Container>;
  }

  // Handling errors
  if (error) {
    return <Container><h2>Error: {error}</h2></Container>;
  }



  return (
  <SearchMain Heading="Delivery Docket" Icon="truck" URL={"/delivery"} SearchEvent={(e)=>setSearch(e)}>
    <Table Format={[
      {key: "Id", label: "#", className: "", style: {width: "20px"}, type: "LINK", callback: (id) => {return (id).toString().padStart(5, "0")} },
      {key: "Name", label: "To", className: "", style: {}, type: "LINK", linkKey: "Id" },
      {key: "Address", label: "Address", className: "", style: {}, type: "TEXT" },
      {key: "CreatedDateTime", label: "Created", className: "", style: {}, type: "TIMEAGO" },
      {key: "UserId", label: "Created By", className: "", style: {}, type: "USER" },
    ]} Data={data}/>
  </SearchMain>
    )
}

export default DeliveryDockets;