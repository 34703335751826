import React, { Component } from 'react';
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import {LandscapeOrientation} from '../../LandscapeOrientation.jsx'
import '../../JobEdit.css';
import { PortraitOrientation } from '../../PortraitOrientation.jsx';
String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + 1);
}
class PurchaseOrderEditPrint extends Component {
    state = {
        loading: true,
        loadingMsg: "Loading purchase order...",
        redirect: "",
        buttonState: false,
        processes: [],
        disabled: false,
        Supplier: null,
        Suppliers: [],
        Users: [],
        SupplierSuggestions: [],
        JobIdSuggestions: [],
        Companies: [],
        Contacts: [],
        Jobs: [],
        ItemData: [
            {
            AssocJobs: [],
            Description: "",
            Price: "",
            Quantity: ""
        }],
        data: {
            SupplierId: -1,
            SupplierName: "",
            Number: "",
            Number2: "",
            Address: "",
        },
        RowDescription: "",
        RowJobSearch: "",
        RowJobs: [],
        RowPrice: "",
        RowQuantity: ""
    }
    UserName = (e) =>{
        const {Users} = this.state;
        for(let i=0; i<Users.length; i++){
            if(Users[i].Id===e){
                return Users[i].Name;
            }
        }
        return " - ";
    }
    getUser = (UserId)=>{
        const {Users} = this.state;
        for(var i=0; i<Users.length; i++){
            if(Users[i].Id==UserId){
                return Users[i];
            }
        }
        return {
            Name: "null"
        }
    }
    async componentDidMount(){
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        var Suppliers = await databaseRequest("/suppliers","GET");
        var Jobs = await databaseRequest(`/jobs?State:not=8&State:not=11&State:not=20`, 'GET');
        var companies = await databaseRequest("/companies","GET");
        var contacts = await databaseRequest("/contacts","GET");
        var Users = await databaseRequest("/users","GET");
        var data = this.state.data;
        var ItemData = this.state.ItemData;
            
        if(params.PurchaseOrderId!=-1){
            databaseRequest(`/purchase-orders/${params.PurchaseOrderId}`,"GET").then((new_data)=>{
                for(var i=0; i<Suppliers.length; i++){
                    if(Suppliers[i].Id==new_data.SupplierId){
                        t.setState({Supplier: Suppliers[i]});
                        var data = {
                            SupplierId: new_data.SupplierId,
                            SupplierName: Suppliers[i].Name,
                            Number: Suppliers[i].Number,
                            Number2: Suppliers[i].Number2,
                            Address: Suppliers[i].Address,
                            Guid: new_data.Guid,
                            CreatedDateTime: new_data.CreatedDateTime,
                            UserId: new_data.UserId
                        };
                    }
                }
                var Initial = ItemData.pop();

                
                for(var i=0; i<new_data.ItemData.length; i++){
                    ItemData.push({
                        AssocJobs: JSON.parse(new_data.ItemData[i].JobId.replaceAll("\\","")),
                        Description: new_data.ItemData[i].Description.toString().replaceAll("\\n","\n").replaceAll("\\\"","\""),
                        Price: new_data.ItemData[i].Price,
                        Quantity: new_data.ItemData[i].Quantity
                    });
                }


                    t.setState({
                        ItemData: ItemData,
                        Companies: companies,
                        Contacts: contacts,
                        Jobs: Jobs,
                        Suppliers: Suppliers,
                        Users: Users,
                        loading: false,
                        data: data
                    });
            });
        }

    }
    getSupplier = (SupplierId)=>{
        const {Suppliers} = this.state;
        for(var i=0; i<Suppliers.length; i++){
            if(Suppliers[i].Id==SupplierId){
                return Suppliers[i];
            }
        }
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    formatPrice = (price)=>{
        if(typeof price === 'undefined')return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'> - </span></React.Fragment>);
        if(price == 0)return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'> - </span></React.Fragment>);


        var decimals = price%1;
        price = price-decimals;


        var value =  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +(decimals.toFixed(2).toString().substring(1));

        return (<React.Fragment><i className='text-left'>$</i><span className='text-right float-right'>{value}</span></React.Fragment>);
        //
    }
    getCompanyName = (CompanyId,ContactId)=>{
        var {Companies,Contacts} = this.state;
        if(CompanyId==-2){
            for(let i=0; i<Contacts.length; i++){
                if(Contacts[i].Id===ContactId){
                    
                    return "["+Contacts[i].Name+"]";
                }
            }
        }
        for(let i=0; i<Companies.length; i++){
            if(Companies[i].Id===CompanyId){
                
                return Companies[i].Name;
            }
        }
        return null;
    }
    getJobNameFromJob = (JobId)=>{
        var {Jobs} = this.state;
        for(var i=0; i<Jobs.length; i++){
            if(Jobs[i].Id==JobId){
                return JobId;
                //return JobId + " - " + this.getCompanyName(Jobs[i].CompanyId, Jobs[i].ContactId) + " | "+Jobs[i].Name;  
            }
        }
    }
    page = () =>{
        const {params} = this.props;
        var Supplier = this.getSupplier(this.state.data.SupplierId);
        var User = this.getUser(this.state.data.UserId);
        if(this.state.loading){
            return this.loading();
        }
        var total = 0;
        var lastSpace = Supplier.Address.lastIndexOf(" ");
        var supplierAdd = lastSpace>1?Supplier.Address.replaceAt(lastSpace, "\n"):Supplier.Address;
        return (
            <main role="main" className="p-5">
                <PortraitOrientation/>
                <div className="">             
                        <div className="row form-group mb-0">
                        <div className="col-auto p-0">               
                                <img src="/logo-full-purchase-order.svg" className="float-left" alt="" width="400"  />
                                <h1 className="text-black text-left float-left" style={{lineHeight: "1.10rem", marginTop: "3rem", marginLeft: "-13rem"}}><small className="mr-4" >CNC Turning, CNC Milling, Laser Cutting, General Engineering, Machining,<br/>Precision Grinding, Machinery Component Manufacturing, Welding and Fabrication<br/>Ph: (03) 5223 1944<br/>
                                    Email: rob@langeengineering.com.au</small></h1>      
                                <p></p>                  
                            </div>
                            <div className="col p-0 pt-3 text-right">
                                <small className="text-black text-right font-weight-bold">{new Date(this.state.data.CreatedDateTime).toLocaleDateString()}</small>
                                <h3 className="mb-0 text-black">PO#:<b style={{fontSize: "2rem"}}>{params.PurchaseOrderId}</b></h3>
                            </div>
                        </div>
                        <div className="row form-group mb-0 mt-4">
                            <div className="col p-3 border rounded border-black ">    
                                <h2 className="mb-0 text-black" style={{textDecoration: "underline"}}>{Supplier.Name.toUpperCase()}</h2>  
                                <h3 className="mb-0 text-black mt-3" style={{textDecoration: "underline"}} dangerouslySetInnerHTML={{__html: supplierAdd.toUpperCase().replaceAll("\n","<br/>")}}></h3>
                                <h3 className="mb-0 text-black mt-3">PH: {Supplier.Number.toUpperCase()} {Supplier.Number2.length > 0?" / " + Supplier.Number2.toUpperCase():""}</h3>
                                
                            </div>
                            <div className="col p-3  ">  
                            </div>
                            <div className="col p-3 border rounded border-black  text-right" style={{lineHeight: "0.75rem"}}>
                                <h2 className="mb-0 text-black" style={{textDecoration: "underline"}}>DELIVERY ADDRESS</h2>  
                                <h3 className="mb-0 text-black mt-3" style={{textDecoration: "underline"}}>1 DOWSETT STREET<br/>SOUTH GEELONG 
                                    VIC, 3220<br/></h3>
                                <h3 className="mb-0 text-black mt-3" >PH: (03) 5223 1944</h3>
                            </div>
                        </div>
                        <div className="row form-group mt-6">
                        <h3 className='text-black font-italic'>Please supply the following:</h3>
                          <table className="table w-100  table-bordered border-0">
                           <thead>
                            <tr>
                                <th className="text-black " style={{width: "6rem"}}>QTY</th>
                                <th className="text-black">DESCRIPTION</th>
                                {/*<th className="text-black" style={{width: "5rem"}}>RLE No.</th>*/}
                                <th className="text-black text-right" style={{width: "10rem"}}>UNIT PRICE</th>
                                <th className="text-black text-right" style={{width: "10rem"}}>TOTAL</th>
                            </tr>
                           </thead>
                           <tbody > 
                            {this.state.ItemData.map((row,i)=>{
                                total += row.Quantity>0?(row.Quantity * row.Price):0;


                                    return (<tr key={i} className="text-black" >
                                    <td style={{fontSize: "1.17rem"}}>{row.Quantity>0?row.Quantity:"-"}</td>
                                    <td style={{fontSize: "1.17rem"}} >
                                        <span dangerouslySetInnerHTML={{__html: row.Description.replaceAll("\n","<br/>")}}></span>
                                    <h4 className='mt-2 text-right'>{
                                        row.AssocJobs.map((Job, j)=>{
                                            return (<i key={j} style={{fontSize: "0.75rem"}} >{j>0?",":""} #{this.getJobNameFromJob(Job)}</i>)
                                        })
                                    }</h4>


                                    </td>

                                    <td style={{fontSize: "1.17rem"}}>{this.formatPrice(row.Price*1)}</td>
                                    <td style={{fontSize: "1.17rem"}}>{this.formatPrice(row.Quantity * row.Price)}</td>
                                </tr>)
                                

                            })}

                            <tr><td  className='border-0' colSpan={2}></td><th className='text-right border-0 pt-3' style={{fontSize: "0.7rem"}}>SUB-TOTAL</th><td style={{fontSize: "1.17rem"}}>{this.formatPrice(total)}</td></tr>
                            <tr><td  className='border-0' colSpan={2}></td><th className='text-right border-0 pt-3' style={{fontSize: "0.7rem"}}>GST</th><td style={{fontSize: "1.17rem"}}>{this.formatPrice(total*0.1)}</td></tr>
                            <tr><td   className='border-0'colSpan={2}></td><th className='text-right border-0' style={{fontSize: "0.9rem"}}>TOTAL</th><td style={{fontSize: "1.17rem"}}>{this.formatPrice(total*1.1)}</td></tr>
                            </tbody>
                            </table>              
                        </div>


                        <div className="row form-group mb-3" >
                            <div className="col-auto p-0">  
                                <h2 className="text-black text-right"><small>Thank you, </small> <br/>{User.Name.toUpperCase()}</h2>
                            </div>
                            </div>



                </div>
            </main>
        );

    }
    render() {
        return this.page();
    }
}

export default PurchaseOrderEditPrint;