import { LandscapeOrientationA4 } from '../../LandscapeOrientation';

function Print({ID, Document, Version}){
    
    function PrintOut(className){
        return (
            <div className={className} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div className="row mb-0 w-100">
                <div className="col-auto p-0 ">
                    <img src="/logo-full-purchase-order.svg" className="float-left" alt="" width="300" />
                    <h1 className="text-black text-left float-left" style={{ lineHeight: "0.8rem", marginTop: "3.5rem", marginLeft: "-13rem" }}><small className="mr-4" >CNC Turning, CNC Milling, Laser Cutting, General Engineering, Machining,<br />Precision Grinding, Machinery Component Manufacturing, Welding and Fabrication<br />Ph: (03) 5223 1944<br />
                        Email: rob@langeengineering.com.au</small></h1>
                    <p></p>
                </div>
            </div>
            <div className="row mb-0 mt-1 w-100">
                <div className="col p-3 border rounded border-black ">    
                    <h2 className="mb-0 text-black"><small className='text-black mr-2'>TO: </small>{Document.Name.value.toUpperCase()}</h2>  
                    <h3 className="mb-0 text-black mt-3"><small className='text-black  mr-2'> ADDRESS: </small> <div dangerouslySetInnerHTML={{__html: Document.Address.value.toUpperCase().replaceAll("\n","<br/>")}}></div></h3>
                    
                    <h3 className="mb-0 text-black mt-3">
                        <small className='text-black  mr-2'> POST CODE: </small>{Document.PostCode.value}
                        <div className='float-right'><small className='text-black  mr-2'> DATE: </small>{new Date(Document.CreatedDateTime.value).toLocaleDateString()}</div>
                    </h3>
                </div>
            </div>
                <div className="row mb-0 mt-4 text-black w-100"  style={{flexGrow: 1}}>
                    <div className="col p-3 border rounded border-black ">    
                        <h3 className='text-black text-center border-bottom border-dark'>DELIVERY NOTE</h3>
                        <p style={{display: 'table-cell',}} dangerouslySetInnerHTML={{__html: Document.Note.value.replaceAll("\n","<br/>")}}></p>
                    </div>
                </div>
                <div className="row mb-0 mt-4 text-black w-100" >
                    <div className="col-3">    
                        <p className='text-black' style={{ fontSize: "0.8rem", lineHeight: "1rem"}}>Received in good order & condition.</p>
                    </div>
                    <div className='col-9'>
                        <div className='float-right'><small>No.</small><span>{(ID || "").toString().padStart(5,"0")}</span></div>
                    </div>
                </div>
                <div className='row mt-3 w-100'>
                    <div className='col'>
                        <small className='text-black'>Signature</small>
                    </div>
                    <div className='col-9 text-black text-right border-bottom border-dark'>
                        {Document.Signature.value}
                    </div>
                </div>
            </div>

            
        )
    }
    return (<div className='p-4' style={{height: "200mm"}}>
        <LandscapeOrientationA4/>
        <div className='row h-100'>
            <div className='col h-100' style={{ borderRight: "#000000 dashed 1px"}}>{PrintOut("pr-4")}</div>
            <div className='col h-100'>{PrintOut("pl-4")}</div>
        </div>
    </div>)
}


export default Print;