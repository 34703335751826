import React, { useState, useEffect } from 'react';
import {Link, useLocation, useHistory  } from "react-router-dom";
import DatePicker, {registerLocale} from "react-datepicker";
import TimeAgo  from '../Components/TimeAgo';
import { DatabaseRequest } from '../Components/DatabaseRequest';
import ReactToPrint from 'react-to-print';

export const Title = ({Heading, Icon, Name, PrintContent}) =>{
    const [printRef, setPrintRef] = useState(); 
    let history = useHistory();
    return (
        <>
        <h2><i className={"fe fe-" + Icon}></i> {Heading}</h2>
        <div className="row form-group mb-0">
            <div className="col p-0">
                <h1 className="border-bottom">{Name.length==0?"--"+Heading+"--":Name}</h1>
            </div>
            <div className="col p-0">
                <button type="button" className="btn btn-info float-right  ml-2" onClick={()=>{history.goBack()}}>Back</button>
                {typeof PrintContent !== 'undefined' && PrintContent!==null?(
                    <>
                    <ReactToPrint
                        trigger={() => {
                            return <button type="button" className="btn btn-secondary float-right">Print</button>
                        }}
                        content={() => printRef}
                    />
                    <div style={{ display: "none" }}><div ref={el => setPrintRef(el)}>{PrintContent}</div></div>
                    </>
                ):(<></>)}
                
            </div>
        </div>
        </>
    )
}
export const SubTitle = ({CreatedDateTime, UpdatedDateTime, UserId}) =>{
    const [User, setUser] = useState(null);
    useEffect(() => {  
        const fetchData = async () => {
            try {
                const response = await DatabaseRequest(`/users/${UserId}/Name`, "GET");
                if (response.status!=200) {
                throw new Error('Network response was not ok');
                }
                delete response.status;
                setUser(response.data);
            } catch (error) {
                console.error(error.message); 
            } 
        }
        if(UserId!==-1){
            fetchData();
        }
    }, []);
    if(UserId==-1 || User==null){
        return <></>;
    }
    return (
        <div className="form-group">
            {CreatedDateTime==UpdatedDateTime?
            (
            <div className='row'>
                <small className="col text-muted">Created: <TimeAgo time={CreatedDateTime}/> ({new Date(CreatedDateTime).toLocaleDateString()}) by {User}</small>
            </div>
            ):(
            <div className='row'>
                <small className="col text-muted">Updated: <TimeAgo time={UpdatedDateTime}/> ({new Date(UpdatedDateTime).toLocaleDateString()}) by {User}</small>
            </div> 
            )}

 
        </div>
    );
}
export const Group = ({children}) =>{
    return (<form className="w-100">{children}</form>);
}

export const Row = ({children}) =>{
    return (<div className="form-row">{children}</div>);
}

export const DateField = ({Title, Name, ClassName, onUpdate, MinDate, Document, Version})=>{
    const [selectedDate, setSelectedDate] = useState("");
    const [valid, setValid] = useState("");
    useEffect(()=>{
        const defaultValue = (Document || {}).hasOwnProperty(Name)?Document[Name].value:new Date();
        const defaultValid = (Document || {}).hasOwnProperty(Name)?Document[Name].valid:"";
        setSelectedDate(defaultValue);
        setValid(defaultValid);
    }, [Version]);

    const validClass = (valid!=="" && valid?"is-valid ":"") + (valid!=="" && !valid?"is-invalid ":"");

    const DatepickerInput = React.forwardRef(({ value, onClick }, ref) => (
        <input type="text" ref={ref} value={value} className={"form-control text-right " + validClass + (ClassName || "")} onClick={onClick} readOnly />
    ));
    return (
    <div className="form-group col-md-2">
        <label>{Title}</label>
        <div >
        <DatePicker  
        dateFormat="dd/MM/yyyy" 
        minDate={MinDate || (new Date().setMonth(new Date().getMonth() - 12))} 
        placeholderText={Title} 
        selected={selectedDate} 
        onChange={
            (date)=>{
                setSelectedDate(date);
                setValid(onUpdate(Name, date.getTime())?"is-valid": "is-invalid");
            }} 
        withPortal
        customInput={<DatepickerInput />}
        />
        </div>
    </div>   );
}

export const Text = ({Title, Name, ClassName, Required, onUpdate, Document, Version}) =>{
    const [value, setValue] = useState("");
    const [valid, setValid] = useState("");

    useEffect(()=>{
        const defaultValue = (Document || {}).hasOwnProperty(Name)?Document[Name].value:"";
        const defaultValid = (Document || {}).hasOwnProperty(Name)?Document[Name].valid:"";
        setValue(defaultValue);
        setValid(defaultValid);
    }, [Version]);
    

    const validClass = (valid!=="" && valid?"is-valid ":"") + (valid!=="" && !valid?"is-invalid ":"");
    return (
    <div className={"form-group "+ClassName}>
        <label>{Title}</label>
        <input type="text" className={"form-control "+validClass} required={Required||false} placeholder={Title} name={Name} value={value} 
        onChange={
            (e)=>{
                setValue(e.target.value); 
                setValid(onUpdate(Name, e.target.value));
            }}/>
    </div>   
    ); 
}
export const TextArea = ({Title, Name, ClassName, Required, onUpdate, rows, Document, Version}) =>{
    const [value, setValue] = useState("");
    const [valid, setValid] = useState("");

    useEffect(()=>{
        const defaultValue = (Document || {}).hasOwnProperty(Name)?Document[Name].value:"";
        const defaultValid = (Document || {}).hasOwnProperty(Name)?Document[Name].valid:"";
        setValue(defaultValue);
        setValid(defaultValid);
    }, [Version]);

    const validClass = (valid!=="" && valid?"is-valid ":"") + (valid!=="" && !valid?"is-invalid ":"");

    return (
    <div className={"form-group "+(ClassName || "col")}>
        <label>{Title}</label>
        <textarea className={"form-control "+validClass}  rows={rows||"5"} placeholder={Title} name={Name} value={value} required={Required||false} 
        onChange={
            (e)=>{
                setValue(e.target.value); 
                setValid(onUpdate(Name, e.target.value)?"is-valid": "is-invalid");
        }}>
        </textarea>
    </div>   
    ); 
}
export const CommitButtons = ({Guid, Title, Create, onSave, onDelete}) =>{
    const location = useLocation();
    return (
        <>
        <div className="form-row">
            <div className="form-group col-md-6">
                <button type="button" className="btn btn-success" onClick={
                (e)=>{
                    e.target.blur();
                    onSave(e);}
                }>
                    {Create?"Create":"Save"}
                </button>
                {Create?(<></>):(
                <>
                    <button type="button" className="btn btn-danger ml-2" data-toggle="modal" data-target="#documentmodal" >Delete</button>
                    <Link to={`/history/${Guid}/${btoa(location.pathname)}`}>
                        <button type="button" className="btn btn-dark ml-2">History</button>
                    </Link>
                </>)}
            </div>
        </div>
        <div className="modal" id="documentmodal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Delete {Title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>Are you sure you want to delete this {Title.toLowerCase()}?</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={(e)=>{onDelete(e);}}>OK</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                </div>
                </div>
            </div>
        </div>
        </>
    );

}
export const Container =({children}) =>{
    return (
        <main role="main" className="container">
        <div className="p-5">
            {children}
        </div>
        </main>
    )
}