import React, { useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { DatabaseRequest } from '../Components/DatabaseRequest';
import TimeAgo  from '../Components/TimeAgo';

export const Table = ({Format, Data, URL}) => {
    const [TableData, setTableData] = useState(Data);
    const [Users, setUsers] = useState([]);
    const [SortConfig, setSortConfig] = useState({key: "", direction: false});

    useEffect(() => {  
        const fetchData = async () => {
            try {
                const response = await DatabaseRequest(`/users`, "GET");
                if (response.status!=200) {
                throw new Error('Network response was not ok');
                }
                setUsers(response);
            } catch (error) {
                console.error(error.message); 
            } 
        }
        fetchData();
    }, []);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    function RenderRow(data, settings, index){
        var field = (<></>);
        var value = data[settings.key];

        if(typeof settings.callback ==='function'){
            value = settings.callback(value);
        }

        switch(settings.type){
            case 'TEXT': 
                field = (<>{value}</>);
            break;
            case 'LINK':
                field = (<Link to={`${URL}/${data[settings.linkKey || settings.key]}`}>{value}</Link>);
            break;
            case 'TIMEAGO':
                field = isValidDate(new Date(value))?(<><TimeAgo time={value}/> ({new Date(value).toLocaleDateString()})</>):(<>-</>);
            break;
            case 'USER':
                var User = Users.find((user)=>{ return user.Id==value;});
                field = (<>{(User||{}).Name}</>)
            break;
        }
        if(index==0){
            return <th scope="col" className={settings.className} key={index}>{field}</th>
        }
        else{
            return <td className={settings.className} key={index}>{field}</td>
        }
    }

    function SortBy(key){
        let _SortConfig = SortConfig;
        if(key==_SortConfig.key){
            _SortConfig = {key: _SortConfig.key, direction: !_SortConfig.direction};
        }
        else{
            _SortConfig = {key: key, direction: false};
        }
        if(_SortConfig.key!=""){
            setTableData(TableData.sort( ( b, a ) => {
                if ( a[_SortConfig.key] < b[_SortConfig.key] ){
                return _SortConfig.direction?-1:1;
                }
                if ( a[_SortConfig.key] > b[_SortConfig.key] ){
                    return _SortConfig.direction?1:-1;
                }
                return 0;
            }));
            setSortConfig(_SortConfig);
        }

    }

    function SortIcons(){
        return SortConfig.direction?(<i className="fe fe-chevron-down mr-2"></i>):(<i className="fe fe-chevron-up mr-2"></i>);
    }
    return (
        <table className="table table-striped">
            <thead className="thead-dark">
                <tr>
                {Format.map((item, i)=>{
                    return (<th scope="col" key={i} className={item.className + " cursor-pointer"} onClick={(e)=>{SortBy(item.key)}}>{item.label} {item.key==SortConfig.key?SortIcons():(<></>)}</th>);
                })}
              
                </tr>
            </thead>
            <tbody>
                {TableData.map((row, i)=>{
                    return (
                    <tr key={i}> 
                        {Format.map((item, j)=>{      
                            return RenderRow(row, item, j);
                        })}        
                        
 
                    </tr>);
                })}
            </tbody>
        </table>
    )
}
