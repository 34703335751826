import {isMobile} from 'react-device-detect';

export const DatabaseRequest = async (url,method,body=null,upload=false) => {
    var DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';


    var restServer = "";
    var devServer = "http://192.168.1.6:8080";
    //devServer = "http://127.0.0.1:8080";

    return new Promise(function(resolve,reject){
        var opts;
        if(!upload){
          opts = {
            method: method,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('token'),
              'x-mobile': isMobile
            }
          };
        }
        else{
          opts = {
            method: method,
            headers: {
              'Accept': 'application/json',
              'x-auth-token': localStorage.getItem('token')
            }
          };
        }
        if(body!==null){
          opts.body=body;
        }
        let status = 0;
          fetch(`${DEV?devServer:restServer}/api${url}`,opts).then((response)=>{
          if(response.status!==200 && response.status!==404){
            //t.setState({serverActive:false, socketActive: false});
          }
          if(response.status===401){
            throw response.status;
          }
          if(response.status===200){
            //
          }
          status = response.status;
          return response.json();
        }).then((data) => {
          setTimeout(function(){
            if(typeof data === 'string'){
              data = {data: data};
            }
            data.status = status;
            resolve(data);
          }, 10);
          
        },(status)=>{
          reject(status);
        }).catch(function(e) {
          console.log(e);
          console.log("error");
        });
      });
}