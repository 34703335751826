import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import md5 from 'md5';
import Dropzone from 'react-dropzone';
import Autosuggest from 'react-autosuggest';
import DatePicker, {registerLocale} from "react-datepicker";
import enAU from 'date-fns/locale/en-AU';
import "react-datepicker/dist/react-datepicker.css";
import State from '../../Components/State.js';
import TimeAgo from '../../Components/TimeAgo.js';
import DaysToGo from '../../Components/DaysToGo.js';
import queryString from "query-string"
import Breadcrumb from '../../Components/Breadcrumb.js';
import ReactToPrint from 'react-to-print';
import JobsEditPrint from './JobsEditPrint.js';
import { ServerConnection } from '../../Components/ServerConnection.js';
import Sketch from '../Sketch/Sketch.js';
import { DesktopConnection, isSocketConnected } from '../../Components/DesktopConnection.js';
import Holidays from 'date-holidays';
import { is } from 'date-fns/locale';


const TOMORROW = 1;
const END_OF_WEEK = 2;
const WEEK1 = 3;
const WEEK2 = 4;
const MONTH1 = 5;
const MONTH3 = 6;



class JobsEdit extends Component {
    state = {
        loading: true,
        sketch: false,
        JobId: -1,
        loadingMsg: "Loading job...",
        uploading: false,
        uploads: [],
        redirect: "",
        buttonState: false,
        daysToComplete : 0,
        processClicked: false,
        history: [],
        dueDate: null,
        CompanyName: "",
        ContactName: "",
        ContactNumber: "",
        ContactNumber2: "",
        CompanySuggestions: [],
        ContactSuggestions: [],
        Companies: [],
        NextJobId:-1,
        contactNameAutoFocus: false,
        companyNameAutoFocus: false,
        Hours:{
            ActualHours: 0,
            ActualSeconds: 0,
            QuoteHours: 0,
        },
        modalImgSrc: "",
    }
    secondsToTime = (time)=>{
        var seconds = time%60;
        var minutes = ((time - seconds)/60)%60;
        var hours = (time-seconds-(minutes*60))/3600;     

        return `${hours<10?"0"+hours:hours}:${minutes<10?"0"+minutes:minutes}:${seconds<10?"0"+seconds:seconds}`
    }
    phoneNumberFormat = (num)=>{
        
        if(num.length > 2){
            if(num.substring(0,2)=="04" && num.length>4){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
                if(num.length > 8){
                    if(num.charAt(8)!=" "){
                        num = num.substring(0,8)+" "+num.substring(8,num.length);
                    }
                }
            }
            if(num.charAt(0)!="0" && num.length>4 ){
                if(num.charAt(4)!=" "){
                    num = num.substring(0,4)+" "+num.substring(4,num.length);
                }
            }
            if(num.substring(0,2)=="03"){
                if(num.charAt(2)!=" "){
                    num = num.substring(0,2)+" "+num.substring(2,num.length);
                }
                if(num.length > 7){
                    if(num.charAt(7)!=" "){
                        num = num.substring(0,7)+" "+num.substring(7,num.length);
                    }
                }
            }
        }
        return num;
    }
    onChange = (e) => {
        let data = this.state.data;
        data[e.target.name]=e.target.value;
        if(data.IsBusiness==="0"){
            data.CompanyName = "Personal";
            data.CompanyId = -2;
        }
        else{
            if(data.CompanyName === "Personal"){
                data.CompanyName = "";
            }
        }


        data.ContactNumber = data.ContactNumber.toString();
        data.ContactNumber2 = data.ContactNumber2.toString();
        data.ContactNumber = this.phoneNumberFormat(data.ContactNumber);

        data.ContactNumber2 = this.phoneNumberFormat(data.ContactNumber2);

        var contactNameAutoFocus = false;
        var companyNameAutoFocus = false;

        if(e.target.name=="ContactName"){
            if(data.ContactName==""){
                data.ContactId=-1;
                data.ContactNumber="";
                data.ContactNumber2="";
                data.Email="";
                contactNameAutoFocus=true;
                companyNameAutoFocus=false;
                this.setState({ 
                    ContactName: ""
                });  

            }
        }





        this.setState({ 
            CompanyName: data.IsBusiness==="0"?"Personal":data.CompanyName,
            data: data,
            contactNameAutoFocus: contactNameAutoFocus,
            companyNameAutoFocus: companyNameAutoFocus
        });  
    };
    setJobType = (b)=>{
        let data = this.state.data;
        data.QuoteState=b;
        this.setState({ 
            data: data
        });  
    }
    stateChange = async (e) =>{
        const {databaseRequest, params}=this.props;
        let data = this.state.data;
        let t = this;
        data[e.target.name]=e.target.value;
        
        t.setState({
            loading: true,
            data: data,
            loadingMsg: `Updating state...`
        });
        var req = {
            url: `/jobs/${params.JobId}/state/${e.target.value}`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            t.setState({
                loading: false,
                data: data,
                loadingMsg: `Updating state...`
            });
        }, 500);
    }
    invoiceSent = async () =>{
        const {databaseRequest, params}=this.props;
        let data = this.state.data;
        let t = this;
        data.State=11;
        
        t.setState({
            loading: true,
            data: data,
            loadingMsg: `Updating state...`
        });
        var req = {
            url: `/jobs/${params.JobId}/state/11`,
            method: "PUT"
        }
        await databaseRequest(req.url,req.method,JSON.stringify({}));
        setTimeout(()=>{
            t.setState({
                loading: false,
                data: data,
                redirect: "/redirector?r=jobs-invoicing",
                loadingMsg: `Updating state...`
            });
        }, 500);
    }
    calculateWorkDates = (date)=>{

        if(date!==null){
            var today = new Date();
            today.setHours(0,0,0,0);
            var endDate = date;
            endDate.setHours(0,0,0,0);

            var days = 0;
            for(var i=today.getTime(); i<endDate.getTime(); i+=86400000){
                var temp = new Date(i)
                if(temp.getDay()!==5 && temp.getDay()!==6){
                    days++;
                }
            }
            return days;
        }
        
        return 0;
    }
    dateChange = date => {
        let data = this.state.data;
        data.DueDateTime = date.getTime();


        this.setState({
            dueDate: date,
            data:data,
            daysToComplete: this.calculateWorkDates(date)
        });
    };
    setDueDate = (btn, e)=>{
        e.target.blur();
        var d = new Date();

        let data = this.state.data;
       

        Date.isLeapYear = function (year) { 
            return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
        };
        
        Date.getDaysInMonth = function (year, month) {
            return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
        };
        
        Date.prototype.isLeapYear = function () { 
            return Date.isLeapYear(this.getFullYear()); 
        };
        
        Date.prototype.getDaysInMonth = function () { 
            return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
        };
        
        Date.prototype.addMonths = function (value) {
            var n = this.getDate();
            this.setDate(1);
            this.setMonth(this.getMonth() + value);
            this.setDate(Math.min(n, this.getDaysInMonth()));
            return this;
        };

        switch(btn){
            case TOMORROW:
                d.setDate(d.getDate()+1);
            break;
            case END_OF_WEEK:
                d.setDate(d.getDate()+1);
                for(var i=0; i<7; i++){
                    if(d.getDay()==5)break;
                    d.setDate(d.getDate()+1);
                }
            break;
            case WEEK1:
                d.setDate(d.getDate()+7);
            break;
            case WEEK2:
                d.setDate(d.getDate()+14);
            break;
            case MONTH1:
                d.addMonths(1);
            break;
            case MONTH3:
                d.addMonths(3);
            break;
        }
        data.DueDateTime = d.getTime();
        this.setState({dueDate: d, data: data});
    }
    onFileDropped = (acceptedFiles) => {
        let d = this.state.data;
        const {databaseRequest}=this.props;
        this.setState({
            uploading: true
        });

        let formData = new FormData()
  
        acceptedFiles.map(file => {
          formData.append('receipt', file, file.name)
        })
        databaseRequest(`/upload`,`POST`,formData,true).then((data)=>{
            if(typeof data.msg === 'undefined'){
                var {uploads} = this.state;
                uploads.push(data.file);
                this.setState({
                    uploads: uploads,
                    uploading: false
                });
            }
            else{
                this.setState({
                    error: data.msg,
                    uploading: false
                });
            }
        });
        
        
    }
    onFileUpload = (e) =>{
        let d = this.state.data;
        const {databaseRequest}=this.props;
        const files = e.target.files;

        this.setState({
            uploading: true
        });
        const formData = new FormData();

        formData.append('receipt', files[0]);
        
        databaseRequest(`/upload`,`POST`,formData,true).then((data)=>{
            if(typeof data.msg === 'undefined'){
                var {uploads} = this.state;
                uploads.push(data.file);
                this.setState({
                    uploads: uploads,
                    uploading: false
                });
            }
            else{
                this.setState({
                    error: data.msg,
                    uploading: false
                });
            }
        });
    }
    sketchSave = (data) =>{
        const {databaseRequest}=this.props;

        this.setState({sketch: false});
        const returnBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});

        const myFile = new File([returnBlob], "sketch_"+(new Date().getTime())+".svg");

        const formData = new FormData();
        formData.append('receipt', myFile);
        this.setState({
            uploading: true
        });
        databaseRequest(`/upload`,`POST`,formData,true).then((data)=>{
            if(typeof data.msg === 'undefined'){
                var {uploads} = this.state;
                uploads.push(data.file);
                this.setState({
                    uploads: uploads,
                    uploading: false
                });
            }
            else{
                this.setState({
                    error: data.msg,
                    uploading: false
                });
            }
            this.saveBtn();
        });

    }
    sketchClose = () =>{
        this.setState({sketch: false});
    }
    deleteFile = (guid) =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/upload/${guid}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading jobs...",
                    redirect: `/redirector?r=jobs/${params.JobId}`,
                });
            }, 1000);
        });
    }
    saveBtn = (e,redirect="") =>{
        const {databaseRequest,params,setPage}=this.props;
        const query = queryString.parse(this.props.location.search);
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Saving ${this.state.data.Name}...`
        });
        var data = this.state.data;

        var Uploads = [];
        for(let i=0; i<this.state.uploads.length; i++){
            Uploads.push(this.state.uploads[i].Id);
        }
        data.Uploads = JSON.stringify(Uploads);
        var req = {
            url: `/jobs/${params.JobId}`,
            method: "PUT"
        }
        if(params.JobId==="-1"){
            req.url="/jobs";
            req.method="POST";
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify(data)
        ).then((data)=>{
            setTimeout(function(){
                setPage(`${data.Name}`);
                t.setState({
                    loading: false,
                    loadingMsg: "Loading jobs...",
                    redirect: redirect.length>1?("/redirector?r=jobs/"+data.Id+"/"+redirect+"?return="+query.return):"/redirector?r=jobs/"+data.Id+"?return="+query.return,
                    data: data
                });
            }, 250);
        });
    }
    data = (key) =>{
        let data = this.state.data;
        if(key in data){  
            return data[key];
        }
        else{
            return "";
        }
    }
    deleteBtn = () =>{
        const {databaseRequest,params}=this.props;
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: "Deleting "+this.data("Name")+"..."
        });
        databaseRequest(`/jobs/${params.JobId}`,"DELETE").then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading jobs...",
                    redirect: "/jobs-active"
                });
            }, 1000);
        });

    }
    duplicateJob = ()=>{
        const {databaseRequest,params}=this.props;
        const query = queryString.parse(this.props.location.search);
        let t = this;
        this.setState({
            loading: true,
            loadingMsg: `Duplicating ${this.state.data.Name}...`
        });
        var req = {
            url: `/jobs/${params.JobId}/duplicate`,
            method: "POST"
        }
        databaseRequest(req.url,req.method, 
            JSON.stringify({})
        ).then((data)=>{
            setTimeout(function(){
                t.setState({
                    loading: false,
                    loadingMsg: "Loading jobs...",
                    redirect: "/redirector?r=jobs/"+data.Id+"?return="+query.return,
                    data: data
                });
            }, 250);
        });
    }
    toggleUrgency = async () =>{
        const {databaseRequest,params,setPage}=this.props;
        let t = this;
        const {data}=this.state;
        data.IsUrgent = data.IsUrgent==="1"?"0":"1";
        this.setState({loading: true,data:data, loadingMsg: `Updating urgency...`});
        if(params.JobId!=-1){
            var req = {
                url: `/jobs/${params.JobId}/urgency/${data.IsUrgent}`,
                method: "PUT"
            }
            await databaseRequest(req.url,req.method,JSON.stringify({}));
            setTimeout(()=>{
                t.setState({
                    loading: false,
                    data: data,
                    loadingMsg: `Updating state...`
                });
            }, 500);
        }
        else{
            t.setState({
                loading: false,
                loadingMsg: `Updating state...`
            });
        }
    }
    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }
    updateNextJobId = async ()=>{
        const {databaseRequest}=this.props;
        var data = await databaseRequest("/jobs/next-job-id","GET");
        this.setState({NextJobId: data.NextJobId});
    }
    setImgModal = (img) =>{
        this.setState({modalImgSrc: img});
    }
    async componentDidMount(){
        const {databaseRequest,params,setPage,socketServer}=this.props;
        let t = this;
        
        /*
        t.socket = io(socketServer);
        t.socket.on('connect', ()=>{
            t.socket.on('server-update', ()=>{
                t.updateNextJobId();
            });
        });*/
        t.updateNextJobId();

        var Companies = await databaseRequest("/companies","GET");
        var Contacts = await databaseRequest("/contacts","GET");
        var Users = await databaseRequest("/users","GET");

        


        
   

        for(let i=0; i<Companies.length; i++){
            Companies[i].contacts = [];
            for(let j=0; j<Contacts.length; j++){
                if(Companies[i].Id===Contacts[j].CompanyId){
                    Companies[i].contacts.push(Contacts[j]);
                }
            }
        }
        if(params.JobId==="-1"){
            setPage(`New Job`);
            t.setState({
                JobId: params.JobId,
                Companies: Companies,
                Users: Users,
                daysToComplete: this.calculateWorkDates(new Date()),
                data:{
                    Id: params.JobId,
                    Name: "",
                    CompanyId: -1,
                    ContactId: -1,
                    CompanyName: "",
                    ContactName: "",
                    ContactNumber: "",
                    ContactNumber2: "",
                    Attachments: "",
                    Scope: "",
                    State: 0,
                    IsBusiness: "1",
                    IsUrgent: "0",
                    Email: "",
                    QuoteNumber: "",
                    PONumber: "",
                    UpdatedDateTime: new Date().getTime(),
                    DueDateTime: new Date().getTime(),
                    CreatedDateTime: new Date().getTime(),
                    CompletedDateTime: -1,
                    QuoteState: 1,
                    Uploads: []
                },
                disabled: false,
                loadingMsg: "Loading job...",
                loading: false
            });
        }
        else{
            var Job = await databaseRequest(`/jobs/${params.JobId}`,"GET");
            var Hours = await databaseRequest(`/jobs/hours/=${params.JobId}`,"GET");
            if(typeof Job.Id !=='undefined'){
                var Company = await databaseRequest(`/companies/${Job.CompanyId}`,"GET");
                var Contact = await databaseRequest(`/contacts/${Job.ContactId}`,"GET");
                var history = await databaseRequest(`/history/${Job.Guid}`,"GET");

                var uploadList = [];
                var uploads = [];
                try{
                    uploadList = JSON.parse(Job.Uploads);      
                    for(let i=0; i<uploadList.length; i++){
                        var result = await databaseRequest(`/upload/${uploadList[i]}`,"GET");
                        if(result.length==1){
                            uploads.push(result[0]);
                        }
                    }
                }
                catch(e){
                    console.log(e);
                }
                setPage(`[${Job.CompanyId==-2?Contact.Name:Company.Name}] - ${Job.Name} | Job`);
                t.setState({
                    Companies: Companies,
                    Job: Job,
                    Company: Company,
                    Contact: Contact,
                    Users: Users,
                    CompanyName: Company.Name,
                    ContactName: Contact.Name,
                    history: history,
                    dueDate: new Date(Job.DueDateTime),
                    uploads: uploads,
                    daysToComplete: this.calculateWorkDates(new Date(Job.DueDateTime)),
                    Hours: Hours,
                    data:{
                        Id: params.JobId,
                        Name: Job.Name,
                        CompanyId: Job.CompanyId,
                        ContactId: Job.ContactId,
                        CompanyName: Company.Name,
                        ContactName: Contact.Name,
                        ContactNumber: Contact.Number,
                        ContactNumber2: Contact.Number2,
                        IsBusiness: (Job.IsBusiness).toString(),
                        IsUrgent: (Job.IsUrgent).toString(),
                        Email: Contact.Email,
                        Attachments: "",
                        Scope: Job.Scope.toString().replaceAll("\\n","\n"),
                        State: Job.State,
                        QuoteNumber: Job.QuoteNumber,
                        PONumber: Job.PONumber,
                        DueDateTime: Job.DueDateTime,
                        OriginalJobId: Job.OriginalJobId,
                        CreatedDateTime: Job.CreatedDateTime,
                        UpdatedDateTime: Job.UpdatedDateTime,
                        CompletedDateTime: Job.CompletedDateTime,          
                        Guid: Job.Guid,
                        QuoteState: Job.QuoteState,
                        Uploads: uploadList

                    },
                    disabled: !this.inUserList([2,3,6,7]), //Job.State===8 || Job.State===11 || Job.State===20,
                    loadingMsg: "Loading job...",
                    loading: false,
                    
                });
            }
            else{
                t.setState({
                    loadingMsg: "Job listing not found...",
                });
                setTimeout(()=>{
                    t.setState({
                        loading: false,
                        redirect: "/jobs",
                    });
                }, 2000);
            }
        }
    }
    componentWillReceiveProps(nextProps){
        if(this.props.params.JobId!==nextProps.params.JobId){
            this.setState({redirect: "/redirector?r=jobs/"+nextProps.params.JobId})
        }
    }
    UserName = (e) =>{
        const {Users} = this.state;
        for(let i=0; i<Users.length; i++){
            if(Users[i].Id===e){
                return Users[i].Name;
            }
        }
        return " - ";
    }
    onCompanySelected = (event, {suggestion}) =>{
        var {data}=this.state;
        data.CompanyId = suggestion.Id;
        data.CompanyName = suggestion.Name;
        this.setState({
            data: data,
            CompanyName: suggestion.Name
        });
    }
    onCompanyNameChange = (event, {newValue}) => {
        var {data}=this.state;
        var companyFound = -1;
        var companies = this.state.Companies;

        if(data.CompanyId !== -1){
            for(let i=0; i<companies.length; i++){
                if(companies[i].Id===data.CompanyId){
                    companies[i].Name = newValue;
                }            
            }
        }
        for(let i=0; i<companies.length; i++){
            if(newValue===companies[i].Name){
                companyFound=i;
            }            
        }
        if(newValue.length==0){
            companyFound = -1;
        }
        data.CompanyId = companyFound>-1?companies[companyFound].Id:-1;
        data.CompanyName = newValue;

        if(data.CompanyName==""){
            this.clearCompanyContactDetails();
        }
        else{
            this.setState({
                data: data,
                CompanyName: newValue
            });
        }
    };
    onContactSelected = (event, {suggestion}) =>{
        const {data} = this.state;
        data.ContactId = suggestion.Id;
        data.ContactName = suggestion.Name;
        data.ContactNumber = suggestion.Number;
        data.ContactNumber2 = suggestion.Number2;
        data.Email = suggestion.Email;
        this.setState({
            data: data,
            ContactName: suggestion.Name
        });
    };
    onContactNameChange = (event, { newValue }) => {
        var {data}=this.state;
        data.ContactName = newValue;
        this.setState({
            data: data,
            ContactName: newValue
        });
    };
    obsolute_onContactNameChange = (event, { newValue }) => {
        /*MADE OBSOLUTE 21 MARCH 2025 REMOVE AFTER 3 MONTHS */
        var {data}=this.state;
        if(event.type=="click"){
            var contactFound = -1;
            var companyFound = -1;
            var companies = this.state.Companies; 
            for(let i=0; i<companies.length; i++){
                if(this.state.CompanyName===companies[i].Name){
                    companyFound=i;
                    for(let j=0; j<companies[i].contacts.length; j++){
                        if(companies[i].contacts[j].Name===newValue){
                            contactFound=j;
                        }
                    } 
                }

                
            }
            data.CompanyId = companyFound>-1?companies[companyFound].Id:-1;
            data.ContactId = contactFound>-1?companies[companyFound].contacts[contactFound].Id:-1;  
            data.ContactName = newValue;
            data.ContactNumber = contactFound>-1&&companyFound>-1?companies[companyFound].contacts[contactFound].Number:"";
            data.ContactNumber2 = contactFound>-1&&companyFound>-1?companies[companyFound].contacts[contactFound].Number2:"";
            data.Email = contactFound>-1&&companyFound>-1?companies[companyFound].contacts[contactFound].Email:"";
        }
        else{
            data.ContactName = newValue;
        }
        this.setState({
            data: data,
            ContactName: newValue
        });
    };
    getCompanyName = suggestion => suggestion.Name;
    getContactName = suggestion => suggestion.Name;

    getCompanySuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.Companies.filter(lang =>
          lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };
    getContactSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const {data, Companies} = this.state;
        var Contacts = [];
        if(data.IsBusiness === "1"){
            if(data.CompanyId !== -1){
                var company = Companies.find((item)=>{return item.Id==data.CompanyId});
                if(typeof company !== 'undefined'){
                    Contacts = company.contacts.filter((item)=>{return item.IsHidden==0});
                }
            }
            return inputLength === 0 ? Contacts : Contacts.filter(lang =>
                lang.Name.toLowerCase().slice(0, inputLength) === inputValue
            );
        } 
        else{
            for(let i=0; i<Companies.length; i++){
                for(let j=0; j<Companies[i].contacts.length; j++){
                    Contacts.push(Companies[i].contacts[j]);
                }               
            }
            var search = inputLength === 0 ? [] : Contacts.filter(lang => lang.Name.toLowerCase().slice(0, inputLength) === inputValue);
            search.sort((a,b)=>{
                return b.Email.length - a.Email.length || b.Number.length - a.Number.length || a.Id - b.Id
            });          

            return search;
        }



        
        
    };
    obsolute_getContactSuggestions = (value) => {
        /*MADE OBSOLUTE 21 MARCH 2025 REMOVE AFTER 3 MONTHS */
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        var companies = this.state.Companies;        
        var found = false;
        var companyIndex = -1;
        for(let i=0; i<companies.length; i++){
            if(companies[i].Name===this.state.CompanyName){
                found=true;
                companyIndex = i;
            }
            
        }
        var Contacts = [];
        if(found){
            for(let i=0; i<companies[companyIndex].contacts.length; i++){
                if(companies[companyIndex].contacts[i].IsHidden==0){
                    Contacts.push(companies[companyIndex].contacts[i]);
                }       
            }
            return inputLength === 0 ? Contacts : Contacts.filter(lang =>
                lang.Name.toLowerCase().slice(0, inputLength) === inputValue
            );
        }
        else{
            for(let i=0; i<companies.length; i++){
                for(let j=0; j<companies[i].contacts.length; j++){
                    Contacts.push(companies[i].contacts[j]);
                }               
            }
            return inputLength === 0 ? [] : Contacts.filter(lang =>
                lang.Name.toLowerCase().slice(0, inputLength) === inputValue
            );
        }


    };
    shouldRenderSuggestions = (value) =>{
        return true;
    }
    renderCompanySuggestion = suggestion => {
        return (<div>{suggestion.Name}</div>);
    };
    renderContactName = (suggestion) => {
        return (<>{suggestion.Name} - ({suggestion.Number}) </>);
    }
    onCompanyNameFetchRequested = ({ value }) => {
        this.setState({
            CompanySuggestions: this.getCompanySuggestions(value)
        });
    };
    onCompanyNameClearRequested = () => {
        this.setState({
            CompanySuggestions: []
        });
    };
    onContactNameFetchRequested = ({ value }) => {
        this.setState({
            ContactSuggestions: this.getContactSuggestions(value)
        });
    };
    onContactNameClearRequested = () => {
        this.setState({
            ContactSuggestions: []
        });
    };
    showCompanyName = () =>{
        if(this.state.data.CompanyName.length==0 || this.state.data.CompanyName=="Personal"){
        return (<React.Fragment>{this.data("ContactName")} - </React.Fragment>);
        }
        return (<React.Fragment>{this.data("CompanyName")} - </React.Fragment>);
    }
    clearCompanyContactDetails = () =>{
        var {data} = this.state;
        data.CompanyId=-1;
        data.ContactId=-1;
        data.CompanyName="";
        data.ContactName="";
        data.ContactNumber="";
        data.ContactNumber2="";
        data.Email="";
        this.setState({
            data:data,
            CompanyName: "",
            ContactName: "",
            contactNameAutoFocus: false,
            companyNameAutoFocus: true
        });
    }
    loading = () =>{
        return (
            <main role="main" className="container">
                <div className="w-100 mt-5 text-center">
                    <h1 className="h3 mb-3 font-weight-normal">{this.state.loadingMsg}</h1>
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </main>
        )
    }
    inUserList = (arr) =>{
        var UserId = localStorage.getItem('user');
        //UserId = 8; Test users here
        var found = false;
        for(let i=0; i<arr.length; i++){
            if(arr[i]==UserId || arr[i]==-1){
                found=true;
            }
        }
        return found;
    }
    createFolder = (sendMessage)=>{
        var {data} = this.state;
        var date = new Date(data.CreatedDateTime);
        var yyyymmdd = date.getFullYear()*10000 + (date.getMonth()+1)*100 + date.getDate();
        if(data.Id !== -1){
            sendMessage("make_folder", {
                IsBusiness: data.IsBusiness,
                JobId: data.Id,
                Date: yyyymmdd,
                Name: data.Name,
                Client: (data.CompanyName.length==0 || data.CompanyName=="Personal")?data.ContactName:data.CompanyName,
                Number: data.ContactNumber

            })
        }

    }
    page = () =>{
        let t = this;
        const {props} = this;
        const { CompanyName, CompanySuggestions, ContactSuggestions, ContactName, data , history} = this.state;

        const query = queryString.parse(this.props.location.search);
        // Autosuggest will pass through all these props to the input.

        var createdBy = "";
        var lastUpdateBy = "";
        if(history.length > 0){
            lastUpdateBy = history[0].Name;
            createdBy = history[history.length-1].Name;
        }


        if(this.state.loading){
            return this.loading();
        }
        if(this.state.redirect.length>1){
            return (
                <Redirect to={this.state.redirect} />
            )
        }
        const DatepickerInput = ({ ...props }) => (
            <input type="text" {...props} readOnly />
          );
        var companyNameIP = {
            placeholder: 'Company Name',
            value: CompanyName,
            className: "form-control",
            onChange: this.onCompanyNameChange,
            disabled: this.state.disabled,
            autoComplete: "nofill",
            autoFocus: this.state.companyNameAutoFocus
          };
          var contactNameIP = {
              placeholder: 'Contact Name',
              value: ContactName,
              className: "form-control",
              onChange: this.onContactNameChange,
              disabled: this.state.disabled,
              autoComplete: "nofill",
              autoFocus: this.state.contactNameAutoFocus
          };

        return (
            <main role="main" className="container  sheet">
            <ServerConnection serverURL={props.socketServer} serverUpdate={(<></>)} fnc={()=>{
                console.log("Server updated");
                t.updateNextJobId();
            }} />
            <div className="p-5">
                <h2><i className="fe fe-flag"></i> Jobs</h2>
                <div className="row form-group">
                    <Breadcrumb {...props} JobId={this.state.data.Id} Active="Job" Disabled={false}/>
                </div>
                <div className="row form-group">
                    <div className="col p-0">
                        <h1 className={this.data("IsUrgent")==="1"?"rounded bg-danger font-weight-bold text-white p-2 border-bottom":"border-bottom p-2"}>[{this.state.data.Id==-1?this.state.NextJobId:this.state.data.Id}] {this.showCompanyName()} <i>{this.state.data.Name.length==0?"New Job":this.data("Name")}</i></h1>
                        <small className="text-muted">Created: <TimeAgo time={this.state.data.CreatedDateTime}/> ({new Date(this.state.data.CreatedDateTime).toLocaleDateString()}) by {createdBy}</small>
                        <br/>
                        <small className="text-muted">Last Updated: <TimeAgo time={this.state.data.UpdatedDateTime}/> ({new Date(this.state.data.UpdatedDateTime).toLocaleDateString()}) by {lastUpdateBy}</small>
                        <br/>
                        <small className="text-muted"><b>Total Time Worked: {this.secondsToTime(Math.floor(this.state.Hours.ActualSeconds))}</b></small>
                        <br/>
                        {this.state.data.CompletedDateTime>-1?(<small className="text-muted"><b>Lead Time: {(((this.state.data.CompletedDateTime-this.state.data.CreatedDateTime)/86400000).toFixed(0))} day(s)</b></small>):(<React.Fragment></React.Fragment>)}           
                    

                    </div>
                    <div className="col p-0">


                        {this.state.data.Id==-1?(
                            <React.Fragment>
                                <button type="button" disabled className="btn btn-secondary float-right ml-2">Print (Save first)</button>
                            </React.Fragment>):(
                            <React.Fragment>
                                <ReactToPrint
                                    trigger={() => {
                                        return <button type="button" className="btn btn-secondary float-right ml-2">Print</button>
                                    }}
                                    content={() => this.componentRef}
                                />
                                <div style={{ display: "none" }}><JobsEditPrint {...this.props} ref={el => (this.componentRef = el)} /></div>
                            </React.Fragment>
                        )}


                        {/*
                        typeof query.return === 'undefined'?
                        (
                            <Link to="/home"><button type="button" className="btn btn-info float-right">Back</button></Link> 
                        ):
                        (
                            <Link to={query.return}><button type="button" className="btn btn-info float-right">Back</button></Link> 
                        )*/}
                        <button type="button" className="btn btn-info float-right" onClick={()=>{this.props.history.goBack()}}>Back</button>


                        {!this.state.disabled?<>
                        {this.data("IsUrgent")==="1"?(
                            <button type="button" className="btn btn-danger float-right mr-2" onClick={this.toggleUrgency}>Urgent</button>
                        ):(
                            <button type="button" className="btn btn-white float-right mr-2" onClick={this.toggleUrgency}>Standard</button>
                        )}
   
                        {this.state.data.State==8?(
                        <button type="button" onClick={()=>{this.invoiceSent()}} className="btn btn-success float-right mr-2">Invoice Sent</button>
                        ):(<React.Fragment></React.Fragment>)}

                        {this.state.data.OriginalJobId>-1?(

                            <Link to={`/jobs/${this.state.data.OriginalJobId}?return=/jobs/${this.state.data.Id}?return=${query.return}`}><button type="button" className="btn btn-warning float-right mr-2">View Original</button></Link> 

                        ):(<React.Fragment></React.Fragment>)}

                        




                            {this.props.params.JobId!=="-1"?(
                                <Link to={`/jobs/${this.state.data.Id}/processes?return=${query.return}`}>
                                    <button type="button" className="btn btn-primary mr-2 float-right" >Allocations</button>
                                </Link>
                            ):(
                            <React.Fragment>
                                <button type="button" className="btn btn-primary mr-2 float-right" onClick={(e)=>{this.saveBtn(e, `processes`)}}>Allocations</button>                           
                            </React.Fragment>)}
                            </>:<></>}
                            {data.Id > -1?(
                            <DesktopConnection
                            topic="make_folder"
                                        isConnected={(sendMessage) => {
                                            return (<button type="button" onClick={() => {this.createFolder(sendMessage)}} className="btn btn-warning float-right mr-2">Open Folder</button>)
                                        }}
                                />
                            ):(<></>)}

                            
                    </div>
                </div>
                <div className="row">
                    <form className="w-100" >



                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="IsBusiness" disabled={this.state.disabled} onChange={this.onChange} value="1" checked={this.state.data.IsBusiness === "1"}/>
                                <label className="form-check-label" >Business</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="IsBusiness" disabled={this.state.disabled} onChange={this.onChange} value="0" checked={this.state.data.IsBusiness === "0"}/>
                                <label className="form-check-label">Personal</label>
                            </div>
                        </div>
                    </div>
                            {/** */}
                    {this.state.data.IsBusiness==="1"?(
                    <div className="form-row d-flex">
                        <div className="form-group col-md-3">
                            <label>Company Name</label>
                            {!(this.state.data.CompanyId>-1)?(
                            <Autosuggest
                                id="AS_CompanyName"
                                suggestions={CompanySuggestions}
                                onSuggestionsFetchRequested={this.onCompanyNameFetchRequested}
                                onSuggestionsClearRequested={this.onCompanyNameClearRequested}
                                onSuggestionSelected={this.onCompanySelected}
                                getSuggestionValue={this.getCompanyName}
                                renderSuggestion={this.renderCompanySuggestion}
                                inputProps={companyNameIP}
                            />
                            ):(
                                <input type="text" className="form-control"  placeholder="Company Name"  disabled={this.state.disabled} name="CompanyName" value={this.data("CompanyName")} onChange={(e)=>{this.onCompanyNameChange(e, {newValue: e.target.value})}}/>
                            )}
    
                                
                        </div>
                        <div className="form-group col-md-1 align-self-end">
                            <Link to={`/system/companies/${this.state.data.CompanyId}`}>
                                <button className="btn btn-info" type="button"><i className="fe fe-search"></i></button>
                            </Link>
                        </div>

                        {this.state.data.Id>-1?(
                              this.state.data.State===11?(
                                <div className="form-group col-md-3 ml-auto">
                                    <b>Job Closed & Invoiced</b>
                                </div>
                            ):(
                            <div className="form-group col-md-3 ml-auto">
                                <label>Job State</label>
                                <select className="form-control" name="State" disabled={this.state.disabled} value={this.data("State")} onChange={this.stateChange}>
                                    <State options={true}/>
                                </select>  
                            </div>) 
                            ):(<React.Fragment></React.Fragment>)} 
                    </div>
                    ):(                    
                    <div className="form-row justify-content-between">
                    <div className="form-group col-md-3">
                        </div>
                            {this.state.data.Id>-1?(
                                this.state.data.State===11?(
                                    <div className="form-group col-md-4">
                                        <b>Job Closed & Invoiced</b>
                                    </div>
                                ):(
                                <div className="form-group col-md-4">
                                    <label>Job State</label>
                                    <select className="form-control" name="State" disabled={this.state.disabled} value={this.data("State")} onChange={this.stateChange}>
                                        <State options={true}/>
                                    </select>  
                                </div>) 
                            ):(<React.Fragment></React.Fragment>)} 

                        </div>)}


                    
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <label>Contact Name</label>
                            {!(this.state.data.ContactId>-1)?(
                            <Autosuggest
                                id="AS_ContactName"
                                suggestions={ContactSuggestions}
                                onSuggestionsFetchRequested={this.onContactNameFetchRequested}
                                onSuggestionsClearRequested={this.onContactNameClearRequested}
                                onSuggestionSelected={this.onContactSelected}
                                shouldRenderSuggestions={this.shouldRenderSuggestions}
                                getSuggestionValue={this.getContactName}
                                renderSuggestion={this.renderContactName}
                                inputProps={contactNameIP}
                            />
                            ):(
                                <input type="text" className="form-control"  placeholder="Contact Name"  disabled={this.state.disabled} name="ContactName" value={this.data("ContactName")} onChange={this.onChange}/>
                            )}
                        </div>

                        <div className="form-group col-md-3">
                            <label>Contact Number</label>
                            <input type="tel" pattern="[0-9]" noValidate className="form-control" autoComplete="nofill" placeholder="Contact Number" disabled={this.state.disabled} name="ContactNumber" value={this.data("ContactNumber")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>Additional Number</label>
                            <input type="tel" pattern="[0-9]" noValidate className="form-control" autoComplete="nofill" placeholder="Contact Number" disabled={this.state.disabled} name="ContactNumber2" value={this.data("ContactNumber2")} onChange={this.onChange}/>
                        </div>
                        <div className="form-group col-md-3">
                            <label>E-mail</label>
                            <input type="text" className="form-control" autoComplete="nofill" placeholder="E-mail"  disabled={this.state.disabled} name="Email" value={this.data("Email")} onChange={this.onChange}/>
                        </div>
                        {!this.state.disabled && (this.state.data.CompanyId > -1 || this.state.data.ContactId > -1)?
                        (<div className="form-group col-md-1 align-self-end">
                            <button type="button" className="btn btn-danger" onClick={this.clearCompanyContactDetails}><i className="fe fe-x"></i></button>
                        </div>)
                        :(<React.Fragment></React.Fragment>)
                        }
                    </div>

                    <div className="form-row ">
                        <div className="form-group col-md-3">
                            <label>Quote Number</label>
                            <input type="tel" pattern="[0-9]" className="form-control" autoComplete="nofill" placeholder="Quote Number"  disabled={this.state.disabled} name="QuoteNumber" value={this.data("QuoteNumber")} onChange={this.onChange}/>    
                        </div>
                        <div className="form-group col-md-3">
                            <label>PO Number</label>
                            <input type="tel" pattern="[0-9]" className="form-control" autoComplete="nofill" placeholder="PO Number"  disabled={this.state.disabled} name="PONumber" value={this.data("PONumber")} onChange={this.onChange}/>    
                        </div>
                    </div>

                    <div className="form-row justify-content-between border-top border-muted mt-4 pt-4">
 
                    <div className="form-group col-md-10">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>Job Name</label>
                                    <input type="text" autoComplete="off" disabled={this.state.disabled} className="form-control" placeholder="Name" name="Name" value={this.data("Name")} onChange={this.onChange}/>
                                </div> 
                                <div className="form-group col-md-6">
                                    <label className="float-left w-100">Job Type</label>
                                    <button className={this.data("QuoteState")===0?"btn check-button-selected":"btn check-button"} disabled={this.state.disabled} type="button" onClick={()=>{this.setJobType(0)}}>Quote Required</button>
                                    <button className={this.data("QuoteState")===1?"btn check-button-selected":"btn check-button"}  disabled={this.state.disabled} type="button" onClick={()=>{this.setJobType(1)}}>Cost Plus</button>
                                </div>
                            </div>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-3">
                        <label>Due Date</label>
                        <div >
                        <DatePicker  
                        dateFormat="dd/MM/yyyy" 
                        minDate={new Date().setMonth(new Date().getMonth() - 12)} 
                        disabled={this.state.disabled}  
                        placeholderText="Due Date" 
                        className="form-control text-right" 
                        selected={this.state.dueDate} 
                        onChange={this.dateChange} 
                        withPortal
                        customInput={<DatepickerInput />}
                        />
                        </div>

                        <p className="p-0">
                        <small className="text-info">
                        {this.state.daysToComplete>0?`Due in ${this.state.daysToComplete} business day${this.state.daysToComplete>1?"s":""}`:``}
                        </small>
                        </p>
                    </div>   

                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(TOMORROW, e)}}>Tomorrow</button>
                    </div>
                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(END_OF_WEEK, e)}}>End of Week</button>
                    </div>
                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(WEEK1, e)}}>1 Week</button>
                    </div>
                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(WEEK2, e)}}>2 Weeks</button>
                    </div>
                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(MONTH1, e)}}>1 Month</button>
                    </div>
                    <div className='form-group col'>
                        <label>&nbsp;</label>
                        <button type="button" className="form-control btn btn-info ml-2" onClick={(e)=>{this.setDueDate(MONTH3, e)}}>3 Months</button>
                    </div>

                    
                        
                        
                        
                        
                    




                    </div>

                    <div className="form-row ">
                        <div className="form-group col-mx-auto w-100">
                        <label>Description</label>
                        <textarea className="form-control"  rows="5" name="Scope" disabled={this.state.disabled} value={this.data("Scope")} onChange={this.onChange}></textarea>
                        </div>

                    </div>

                    {/*<div className="form-row mb-3">
                            {this.state.uploads.map((file, i)=>{
                                if(file.Filename.indexOf("sketch_") > -1){
                                            return (<div className="col-mx-auto"><a href={this.props.restServer+"/api/download/"+file.Guid+""} target="_blank" ><img key={i} src={this.props.restServer+"/api/download/"+file.Guid} width="300"/></a></div> )
                                }
                            })
                            }
                    </div>
                    */}
                    {!this.state.disabled?<>
                    <div className="form-group col-mx-auto w-100">
                        <label>Reference Documents</label>
                        

                        {this.state.uploading?(<p>Uploading...</p>):(
                        <React.Fragment>
                            <Dropzone onDrop={acceptedFiles => this.onFileDropped(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                    <div {...getRootProps()}  className="rounded border border-info p-4 text-center">
                                        <input {...getInputProps()}  name="File"  disabled={this.state.disabled} onChange={this.onFileUpload} />
                                        <p>Click here to upload photos, drawings, sketches, etc.</p>
                                    </div>
                                    </section>
                                )}
                                </Dropzone>
                                <p className="text-danger">{this.state.error}</p>   
                        </React.Fragment>
                        )}


                        
                        {/*this.state.uploading?(<p>Uploading...</p>):(
                            <input className='form-control'  name="File" type="file"  disabled={this.state.disabled} onChange={this.onFileUpload} />
                        )*/}
                       
                    </div>
                     </>:<></>}
                    {this.state.uploads.length>0?(
                    <div className="form-row ">
                        <div className="form-group col-mx-auto w-100">
                        <table className="table table-striped ">
                            <thead className="bg-warning">
                                <tr>
                                <th scope="col" className="text-left bg-secondary text-white">File Name</th>
                                <th scope="col" className="text-left bg-secondary text-white" style={{width: '20px'}}></th>
                                <th scope="col" className="text-left bg-secondary text-white">Uploaded By</th>
                                <th scope="col" className="text-left bg-secondary text-white">Date</th>
                                <th scope="col" className="text-left bg-secondary text-white">Size</th>
                                <th scope="col" className="text-left bg-secondary text-white" style={{width: '20px'}}></th>

                                </tr>
                            </thead>
                            <tbody>
                                {this.state.uploads.map((file, i)=>{
                                    const {Filename} = file;
                                    var exts = Filename.split(".");
                                    var isImage = false;

                                    isImage = exts.includes("jpeg")?true:isImage;
                                    isImage = exts.includes("jpg")?true:isImage;
                                    isImage = exts.includes("png")?true:isImage;
                                    isImage = exts.includes("bmp")?true:isImage;
                                    isImage = exts.includes("svg")?true:isImage;
                                    return (
                                    <tr key={i}>
                                        <td>{isImage?(

                                            <img className="link" width="100px" src={this.props.restServer+"/api/download/"+file.Guid+""} onClick={(e)=>{this.setImgModal(this.props.restServer+"/api/download/"+file.Guid+"")}} data-toggle="modal" data-target="#imgModal"/>
                                        
                                        ):(<a href={this.props.restServer+"/api/download/"+file.Guid+""} target="_blank" >{file.Filename}</a>)}</td>
                                        <td><a href={this.props.restServer+"/api/download/"+file.Guid+"/print"} target="_blank" ><i className="fe fe-printer link"></i></a></td>
                                        <td>{this.UserName(file.UserId)}</td>
                                        <td><TimeAgo time={file.DateTime}/>  ({new Date(file.DateTime).toLocaleString()})</td>
                                        <td>{this.bytesToSize(file.Size)}</td>
                                        <td><i className="fe fe-trash-2 link" onClick={()=>{this.deleteFile(file.Guid)}}></i></td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>        
                        </div>          
                    </div>
                    ):(<React.Fragment></React.Fragment>)}



                    {!this.state.disabled?<>
                    <div className="form-row justify-content-between">         
                        <div className="form-group col-mx-auto w-100"> 
                            <label className="w-100">Sketches</label>                                                    
                            <button type="button" className="btn btn-info" disabled={this.state.disabled} onClick={()=>{this.setState({sketch: true})}}><i className="fe fe-edit"></i></button>
                        </div>         
                    </div>  
                    </>:<></>}

                    {false?(
                    <div className="form-row ">
                        <div className="form-group col-mx-auto w-100">
                        <table className="table table-striped ">
                            <thead className="bg-warning">
                                <tr>
                                <th scope="col" className="text-left bg-dark text-white" style={{width: '100px'}}>Sub-Job Number</th>
                                <th scope="col" className="text-left bg-dark text-white">Description</th>
                                <th scope="col" className="text-left bg-dark text-white">Operator(s)</th>
                                <th scope="col" className="text-left bg-dark text-white">Created</th>
                                <th scope="col" className="text-left bg-dark text-white">Due Date</th>

                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>        
                        </div>          
                    </div>
                    ):(<React.Fragment></React.Fragment>)}




                    <div className="form-row justify-content-between">
                        <div className="form-group col-mx-auto">
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-success" disabled={this.state.disabled} onClick={this.saveBtn}>Save</button>
                            ):(
                            <button type="button" className="btn btn-success" disabled={!this.state.buttonState} onClick={this.saveBtn}>Create</button>
                            )}
                            {this.props.params.UserId!=="-1"?(
                            <button type="button" className="btn btn-danger ml-2" disabled={this.state.disabled} data-toggle="modal" data-target="#myModal" >Delete</button>
                            ):(<div/>)}
                            {this.props.params.UserId!=="-1"?(
                            <Link to={`/history/${this.state.data.Guid}/${btoa(this.props.location.pathname)}?return=${query.return}`}>
                                <button type="button" className="btn btn-dark ml-2">History</button>
                            </Link>
                            ):(<div></div>)}


                            {!this.state.disabled?(
                                <button type="button" className="btn btn-warning ml-2" onClick={this.duplicateJob}>Duplicate</button>
                            ):(<div></div>)}

                        </div>
                        <div className="form-group col-mx-auto">

                            {!this.state.disabled?<>
                            {this.props.params.JobId!=="-1"?(
                                <Link to={`/jobs/${this.state.data.Id}/processes?return=${query.return}`}>
                                    <button type="button" className="btn btn-primary ml-2 float-right" >Allocations</button>
                                </Link>
                            ):(
                            <React.Fragment>
                                <button type="button" className="btn btn-primary float-right" onClick={(e)=>{this.saveBtn(e, `processes`)}}>Allocations</button>                           
                            </React.Fragment>)}
                            </>:<></>}
                        </div>
                    </div>
                    </form>
                </div>

                <div className="modal" id="myModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Job</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure you want to delete {this.data("Name")}?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteBtn}>OK</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal" id="imgModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" style={{maxWidth: "1050px"}} role="document">
                        <div className="modal-content">

                        <div className="modal-body">
                            <img src={this.state.modalImgSrc} width="1000px" />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
        );

    }
    render() {
        if(this.state.sketch===true){
            return (<Sketch save={this.sketchSave} close={this.sketchClose}/>)
        }
        
        return this.page();
    }
}

export default JobsEdit;