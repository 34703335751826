export const LandscapeOrientation = () => (
    <style type="text/css">
      {"@page { size: A3 }"}
      {"@media print{@page {size: 420mm 297mm;}}"}
    </style>
  );
  export const LandscapeOrientationA4 = () => (
    <style type="text/css">
      {"@page { size: A4; margin: 0; }"}
      {"@media print{@page {size: 297mm 210mm;}}"}
      {"@media print{html, body{width: 100%; height: 100%;}}"}
    </style>
  );