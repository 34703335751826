import React, { useState, useEffect } from 'react';
import {Redirect} from "react-router-dom";
import Print from './DeliveryDocketEditPrint.js';

import SetPage from '../../Components/SetPage';
import { DatabaseRequest } from '../../Components/DatabaseRequest';
import {Title,SubTitle, Container, Group, Row, Text, TextArea, CommitButtons, DateField} from '../../Standard/EditForm';
import {DeliveryName} from '../../Standard/AutoSuggestions';


function DeliveryDocketsEdit({params}){
    SetPage("Delivery Docket");
    const [loading, setLoading] = useState(true);  
    const [redirector, setRedirector] = useState("");  
    const [error, setError] = useState(null); 
    

    const [version, setVersion] = useState(0);
    const ID = Number(params.DeliveryId) || -1;
    const [document, setDocument] = useState({
        Name: {value: "", valid: "", required: true},
        CreatedDateTime: {value: "", valid: "", required: true},
        UpdatedDateTime: {value: "", valid: "", required: false},
        Address: {value: "", valid: "", required: true},
        PostCode: {value: "", valid: "", required: true},
        Signature: {value: "", valid: "", required: false},
        Note: {value: "", valid: "", required: true},
        UserId: {value: -1, valid: "", required: false},
        Guid: {value: "", valid: "", required: false},
        IsDeleted: {value: "", valid: "", required: false},
    });

    useEffect(() => {  
        const fetchData = async () => {
        try {
            const response = await DatabaseRequest(`/delivery/${ID}`, "GET");
            if (response.status!=200) {
            throw new Error('Network response was not ok');
            }
            delete response.status;
            loadDocument(response);
        } catch (error) {
            setError(error.message); 
        } finally {
            setLoading(false);
        }
        };
        if(ID!==-1){
            fetchData();
        }     
        else{
            setLoading(false);
        }
    }, []);  // Empty dependency array to run only once on mount
    
    function saveDocument(e){
        if(validateDocument()){
            //Save document here
            const saveData = async (newData) => {
                var result = await DatabaseRequest(ID==-1?`/delivery`:`/delivery/${ID}`, ID==-1?"POST":"PUT", JSON.stringify(newData));
                if(result.hasOwnProperty("Id")){
                    setRedirector(`/redirector?r=delivery/${result.Id}`);
                }
            }
            saveData({
                Name: document.Name.value,
                Address: document.Address.value,
                PostCode: document.PostCode.value,
                Signature: document.Signature.value,
                Note: document.Note.value
            })
        }
    }
    function deleteDocument(e){
        const deleteData = async () => {
            await DatabaseRequest(`/delivery/${ID}`, "DELETE", JSON.stringify([]));
            setRedirector(`/redirector?r=delivery`);    
        }
        deleteData();
    }
    function loadDocument(data){
        let _document = document;
        for (var key in _document) {
            if (!_document.hasOwnProperty(key)) continue;
            if (!data.hasOwnProperty(key)) continue;
            _document[key].value=data[key];
        }
        setVersion(version + 1);
        setDocument(_document);
    }
    function validateDocument(_document=document){
        //let _document = document;
        var validDocument = true;
        for (var key in _document) {
            if (!_document.hasOwnProperty(key)) continue;
            if(_document[key].required){
                _document[key].valid = validateField(key, _document[key].value);
                validDocument = !_document[key].valid?false:_document[key].valid;
            }

        }
        setVersion(version + 1);
        setDocument(_document);
        return validDocument;
    }
    function validateField(key, value){
        if(value.length < 1) return false;
        return true;
    }
    function updateDocument(key, value){
        var obj = {};
        obj[key] = {...document[key]};
        obj[key].value = value;
        obj[key].valid = obj[key].required?validateField(key, value):"";
        setDocument({...document, ...obj});
        return obj[key].valid;
    }
    function onToSuggestion(suggestion){
        validateDocument({
            ...document, 
            Name: {...document.Name, value: suggestion.Name},
            Address: {...document.Address, value: suggestion.Address},
            PostCode: {...document.PostCode, value: suggestion.PostCode},
        });
    }
    // Handling the loading state
    if (loading) {
        return (
        <Container>
            <h2>Loading....</h2>
        </Container>);
    }

    // Handling errors
    if (error) {
        return (
        <Container>
            <div>Error: {error}</div>
        </Container>);
    }
    if(redirector.length>1){
        return (<Redirect to={redirector} />)
    }
    const TitleName = ID==-1?"-- New Delivery Docket --":"No. "+ID.toString().padStart(5,"0");
    //return (<Print ID={ID} Document={document} Version={version} />)
    return (
        <Container>
            <Title Heading="Delivery Docket" Icon="truck" Name={TitleName} PrintContent={ID!=-1?(<Print ID={ID} Document={document} Version={version} />):(null)}/>
            <SubTitle CreatedDateTime={document.CreatedDateTime.value} UpdatedDateTime={document.UpdatedDateTime.value} UserId={document.UserId.value} />
            <Group>
                <Row>
                    <DeliveryName Title="Name" Name="Name" Document={document} Version={version} ClassName="col-md-5" onUpdate={updateDocument} onValidSuggestion={onToSuggestion} />
                </Row>
                <Row>
                    <Text Title="Address" Document={document} Version={version} Name="Address" ClassName="col-md-8" onUpdate={updateDocument}/>
                    <Text Title="Post Code" Document={document} Version={version}  Name="PostCode" ClassName="col-md-2" onUpdate={updateDocument}/>
                </Row>
                <Row>
                    <TextArea Title="Note" Name="Note" Document={document} Version={version} onUpdate={updateDocument}/>
                </Row>
                <Row>
                    <Text Title="Signature" Document={document} Version={version}  Name="Signature" ClassName="col-md-2" onUpdate={updateDocument}/>
                </Row>
                <CommitButtons Title="Delivery Docket" Create={ID==-1} onSave={saveDocument} onDelete={deleteDocument} Guid={document.Guid.value}/>
            </Group>
        </Container>
    )
}


export default DeliveryDocketsEdit;