import React, { useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";

function SearchMain({children, Heading, Icon,SearchEvent, URL}){
    const [search, setSearch] = useState("");

    const location = useLocation();
    const history = useHistory();

    useEffect(()=>{
        var query = queryString.parse(location.search);
        if(typeof query.q !== 'undefined'){
            setSearch(query.q);
            SearchEvent(query.q);
        }
    }, [])
    
    return (
        <main role="main" className="container">
            <h2><i className={"fe fe-"+Icon}></i> {Heading}</h2>
                <div className="row form-group">
                    <div className="col p-0">
                        <form className="form-inline">
                            <input className="form-control mr-sm-2" type="search" value={search} 
                            onChange={(e)=>{
                                setSearch(e.target.value);
                                SearchEvent(e.target.value);
                                history.replace(`${location.pathname}?q=${e.target.value}`)
                            }  
                            } 
                            placeholder="Search" aria-label="Search"/> 
                            <div className="form-group col-md-1 align-self-end">
                                <button type="button" className="btn btn-danger" 
                                onClick={(e)=>{
                                    setSearch("");
                                    SearchEvent("");
                                    history.replace(`${location.pathname}`);

                                }}><i className="fe fe-x"></i></button>
                            </div>                     
                        </form>
                    </div>
                    <div className="col p-0">
                        <Link to={URL + "/-1"}><button type="button" className="btn btn-success float-right">New {Heading}</button></Link>
                    </div>
                </div>
                <div className="row">
                    {React.cloneElement(children, { URL: URL })}
                </div>
        </main>
    )
}
export default SearchMain;